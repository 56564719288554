import { z } from "zod";
import {
  ColorValueSchema,
  IconSchema,
  newProductSchema,
  SizeMeasurementSchema,
  SizeSurchargeSchema,
  ThresholdSchema,
  WeightSchema,
} from "./formSchema";

// Infer types from Zod schemas
export type IThreshold = z.infer<typeof ThresholdSchema>;
export type ISizeSurcharge = z.infer<typeof SizeSurchargeSchema>;
export type IWeight = z.infer<typeof WeightSchema>;
export type ISizeMeasurement = z.infer<typeof SizeMeasurementSchema>;
export type IColor = z.infer<typeof ColorValueSchema>;
export type IIcons = z.infer<typeof IconSchema>;

// All in /size-charts/ file
export const SizeChartImagesTemplates = [
  "tshirt-regular-updated.png",
  "tshirt-boxy-updated.png",
  "classic-sweatshirt-updated.png",
  "classic-longsleeves-updated.png",
  "classic-hoodie-updated.png",
  "classic-dad-cap-updated.png",
  "boxy-hoodie-updated.png",
];
export interface ProductColor {
  productImage: string;
  colorName: string;
  colorHex: string;
  SKU?: string;
}
export type ProductColorMode = "image" | "overlay";

export interface ProductImage {
  uuid: string;
  url: string;
  name: string;
}

export const SIZE_ORDER: { [key: string]: number } = {
  "2XS": 0,
  XS: 1,
  S: 2,
  M: 3,
  L: 4,
  XL: 5,
  "2XL": 6,
  "3XL": 7,
  "4XL": 8,
  "5XL": 9,
};

export const defaultData = {
  colorName: "New Color",
  colorCode: "#f1eeee",
  filterContrast: null,
  filterEnhance: null,
  colorMode: "image",
  orientationCardsValue: {
    front: {
      rulerLength: null,
      areaWidth: 0,
      areaHeight: 0,
      flatLay: null,
      productSetterData: {
        rulerX: null,
        rulerY: null,
        ruler2X: null,
        ruler2Y: null,
        areaX: 0,
        areaY: 0,
        neckLineX: null,
        neckLineY: null,
        labelX: null,
        labelY: null,
        heartX: null,
        heartY: null,
        heartWidth: null,
        heartHeight: null,
        _imageWidth: null,
        _imageHeight: null,
        _imagePxPerCm: null,
      },
    },
    back: {
      rulerLength: null,
      areaWidth: 0,
      areaHeight: 0,
      flatLay: null,
      productSetterData: {
        rulerX: null,
        rulerY: null,
        ruler2X: null,
        ruler2Y: null,
        areaX: 0,
        areaY: 0,
        neckLineX: null,
        neckLineY: null,
        labelX: null,
        labelY: null,
        heartX: null,
        heartY: null,
        heartWidth: null,
        heartHeight: null,
        _imageWidth: null,
        _imageHeight: null,
        _imagePxPerCm: null,
      },
    },
    label: {
      rulerLength: null,
      areaWidth: 0,
      areaHeight: 0,
      flatLay: null,
      productSetterData: {
        rulerX: null,
        rulerY: null,
        ruler2X: null,
        ruler2Y: null,
        areaX: 0,
        areaY: 0,
        neckLineX: null,
        neckLineY: null,
        labelX: null,
        labelY: null,
        heartX: null,
        heartY: null,
        heartWidth: null,
        heartHeight: null,
        _imageWidth: null,
        _imageHeight: null,
        _imagePxPerCm: null,
      },
    },
  },
};

export const newProductFormDefaultValues: z.infer<typeof newProductSchema> = {
  name: "",
  description: "",
  productImages: [],
  colorMode: "image",
  colors: [],
  selectedSizes: ["XS", "S", "M", "L", "XL", "2XL"],
  thresholds: [{ threshold: 0, price: 0, cogs: 0, leadTime: 0 }],
  sizeSurcharges: [],
  techFit: "",
  techConstruction: "",
  techFinishing: "",
  techFabric: "",
  techOrigin: "",
  weightUnit: "g",
  weights: [],
  measurementUnit: "cm",
  sizeMeasurements: [],
  sku: "",
  brandId: null,
  sizeChartImage: null,
  handle: "",
  icons: {
    frontIcon: null,
    backIcon: null,
    frontAction1Icon: null,
    frontAction2Icon: null,
  },
};

export const ProductActionIcons = [
  "cap-back.svg",
  "cap-front.svg",
  "cap-label.svg",
  "cap-left.svg",
  "hoodie-back.svg",
  "hoodie-front.svg",
  "hoodie-chest.svg",
  "hoodie-heart.svg",
  "hoodie-label.svg",
  "tshirt-back.svg",
  "tshirt-chest.svg",
  "tshirt-front.svg",
  "tshirt-heart.svg",
  "tshirt-label.svg",
  "crewneck-chest.svg",
  "crewneck-front.svg",
  "crewneck-back.svg",
  "crewneck-heart.svg",
  "crewneck-label.svg",
];

export const iconLabels: any = {
  backIcon: "Back Icon",
  frontIcon: "Front Icon",
  frontAction1Icon: "Chest Icon (Action 1)",
  frontAction2Icon: "Heart Icon (Action 2)",
  labelIcon: "Label Icon",
};

export const orientationsOrder = ["front", "back", "right", "left", "label"];
