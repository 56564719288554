import { CanvaArtwork, CanvaData, CanvaImageItemMetadata } from "@/components/canva/ICanva";
import { Tables } from "@/types";
import { OrientationType } from "@/types/enums/orientationType.enum";
import { assign, cloneDeep, eq, find, flow, keys, map, mapValues, matchesProperty, omit, property } from "lodash";
import fp from "lodash/fp";
import {
  ArtworkCustomization,
  ArtworkFile,
  ColorCustomization,
  LabelCustomization,
  Size,
} from "@/types/supabase-custom/Customization";
import {
  ArtworkType,
  CanvaOrientations,
} from "@/app/studio/(public)/products/[productHandle]/components/reducers/canvaOrientationsReducer";
import { JoinedConfiguration } from "@/types/supabase-custom/JoinedConfiguration";
import { SupabaseUtils } from "../supabase/supabaseUtils";
import { createClient } from "../supabase/client";
import {
  IColor,
  orientationsOrder,
  ProductColorMode,
} from "@/app/admin-store/(authenticated)/products/create/newProductConstants";

const supabaseUtils = new SupabaseUtils(createClient());

export function mergeCanvaArtworkDimenstions(
  artwork: CanvaArtwork,
  { x, y, width, height }: { x: number; y: number; width: number; height: number }
) {
  artwork = cloneDeep(artwork);
  const result = assign(artwork, { location: { x, y }, dimensions: { width, height } });
  return result;
}

export function getCanvaData({
  tenantUUID,
  orientation,
  productUUID,
  colorConfig,
  colorMode,
}: {
  tenantUUID: string;
  orientation: OrientationType;
  productUUID: string;
  colorConfig: IColor;
  colorMode: ProductColorMode;
}) {
  const { _imageWidth, _imageHeight, _imagePxPerCm, areaX, areaY } =
    colorConfig.orientationCardsValue[orientation].productSetterData;

  const [canvaWidthCm, canvaHeightCm, areaXCm, areaYCm] = map(
    [_imageWidth, _imageHeight, areaX, areaY] as number[],
    (n) => n / (_imagePxPerCm || 1)
  );

  const canvaData: CanvaData & { minRequiredSize: Size } = {
    minRequiredSize: Size.L,
    background: {
      imageUrl: supabaseUtils.getImageUrl(
        "products",
        `${tenantUUID}/${productUUID}/${colorConfig.orientationCardsValue[orientation].flatLay}`
      ),
      hex: colorConfig.colorCode,
      filterHex: colorMode == "overlay" ? colorConfig.colorCode : undefined,
      filterContrast: colorConfig.filterContrast || 0,
      filterEnhance: colorConfig.filterEnhance || 0,
    },
    editableArea: !eq(orientation, OrientationType.Label)
      ? {
          width: colorConfig.orientationCardsValue[orientation].areaWidth,
          height: colorConfig.orientationCardsValue[orientation].areaHeight,
          x: areaXCm,
          y: areaYCm,
          visible: true,
        }
      : undefined,
    dimensions: {
      width: canvaWidthCm,
      height: canvaHeightCm,
    },
    artworks: [],
  };

  return canvaData;
}

export function mapCanvaDataToConfig(canvaOrientations: CanvaOrientations, product: Tables<"products">) {
  // color
  const color_metadata = (function getColor() {
    if (!product.colors || product.colors.length === 0) return null;

    const result =
      find(product.colors as IColor[], {
        colorCode: canvaOrientations.front.background?.filterHex,
      }) || (product.colors[0] as IColor);
    return {
      name: result.colorName,
      hex: result.colorCode,
      contrast: result.filterContrast || 0,
      pantone: "",
      category: "pre-developed",
    } as ColorCustomization;
  })();

  // artworks
  const artwork_metadata = (function getArtworks() {
    const result: ArtworkCustomization = {
      minimum_required_size: mapValues(canvaOrientations, (value) => value.minRequiredSize),
      ...mapValues(
        omit(canvaOrientations, OrientationType.Label),
        flow(
          property("artworks"),
          fp.filter(matchesProperty("metadata.type", ArtworkType.body_artwork)),
          fp.map(mapCanvaArtworkToDbArtwork)
        )
      ),
    };

    return result;
  })();

  // label
  const label_metadata = (function getLabel() {
    const labelBgArtwork = find(
      canvaOrientations.label.artworks,
      matchesProperty("metadata.type", ArtworkType.label_background_artwork)
    );
    const labelArtwork = find(
      canvaOrientations.label.artworks,
      matchesProperty("metadata.type", ArtworkType.label_artwork)
    );
    if (!labelBgArtwork) return null;

    const result: LabelCustomization = {
      uuid: labelBgArtwork.uuid,
      type: (labelBgArtwork.metadata as CanvaImageItemMetadata).labelType,
      position: (labelBgArtwork.metadata as CanvaImageItemMetadata).labelPosition,
      size: (labelBgArtwork.metadata as CanvaImageItemMetadata).labelSize,
      stitch: (labelBgArtwork.metadata as CanvaImageItemMetadata).labelStitch,
      url: (labelBgArtwork.metadata as CanvaImageItemMetadata).imageUrl,
      location: labelBgArtwork.location,
      dimensions: labelBgArtwork.dimensions,
      name: labelBgArtwork.name,
      ...(labelArtwork ? { artwork_file: mapCanvaArtworkToDbArtwork(labelArtwork) } : {}),
    };

    return result;
  })();

  return {
    color_metadata,
    artwork_metadata,
    label_metadata,
  };
}

function mapCanvaArtworkToDbArtwork(artwork: CanvaArtwork): ArtworkFile {
  return {
    uuid: artwork.uuid,
    location: artwork.location,
    dimensions: artwork.dimensions,
    width: artwork.dimensions.width,
    height: artwork.dimensions.height,
    url: (artwork.metadata as CanvaImageItemMetadata).imageUrl,
    name: artwork.name,
    size: (artwork.metadata as CanvaImageItemMetadata).size,
    embellishment_type: (artwork.metadata as CanvaImageItemMetadata).embellishmentType,
    color_selection_technique: (artwork.metadata as CanvaImageItemMetadata).colorSelectionTechnique,
    color_selection: (artwork.metadata as CanvaImageItemMetadata).colorSelection,
    is_flipped_y: (artwork.metadata as CanvaImageItemMetadata).isFlippedY,
    is_flipped_x: (artwork.metadata as CanvaImageItemMetadata).isFlippedX,
    from_center: (artwork.metadata as CanvaImageItemMetadata).fromCenter,
    from_neck_seam: (artwork.metadata as CanvaImageItemMetadata).fromNeckSeam,
  };
}

export function getOrientedImagesURLs(config: JoinedConfiguration, size = "md") {
  const product = config.products;
  const colorConfig: IColor | null = (function getSelectedColor() {
    if (!product?.colors || eq(product.colors.length, 0)) {
      return null;
    }

    const result =
      find(
        product.colors as IColor[],
        matchesProperty("colorCode", (config?.customizations as unknown as CanvaOrientations)?.front?.background?.hex)
      ) || (product.colors[0] as IColor);

    return result;
  })();
  const availableOrientations = keys(colorConfig?.orientationCardsValue)
    .sort((a, b) => orientationsOrder.indexOf(a) - orientationsOrder.indexOf(b))
    .filter(
      (orientation) => colorConfig?.orientationCardsValue[orientation as OrientationType].flatLay
    ) as OrientationType[];

  const result = map(availableOrientations, getOrientedImageURL);
  return result;

  function getOrientedImageURL(orientation: OrientationType) {
    return `/api/share/canva/${config.uuid}/${orientation}/${size}/${config.updated_at}`;
  }
}
