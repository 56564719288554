import { add } from "lodash/fp";
import { productStyleType } from "src/types/enums/productStyleType.enum";
import { ColorsPricing } from "./colorsPricingConfig";

type Offset = number[];

export interface ProductSizeDimensions {
  length?: ProductSizeDimension[];
  chest?: ProductSizeDimension[];
  sleeve?: ProductSizeDimension[];
  circumference?: ProductSizeDimension[];
  height?: ProductSizeDimension[];
  brimLong?: ProductSizeDimension[];
  brimWide?: ProductSizeDimension[];
}

export interface ProductSizeDimension {
  size: string;
  dimension: number;
}
interface ImageDimensions {
  width: number;
  height: number;
  leftChest: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
}

interface StandardEmbellishments {
  digitalPrint: boolean;
  screenPrint: boolean;
  embroidery: boolean;
  heatTransfer: boolean;
}

interface CapEmbellishments {
  embroidery: boolean;
}

type DefaultEmbellishments = StandardEmbellishments | CapEmbellishments;

interface ProductConfig {
  canvaDimensions: { [key: string]: number[] };

  // style: string;
  style: productStyleType;
  neckSeamPosition?: { front: Offset; back: Offset };
  capBasePosition?: { front: Offset; back: Offset; left: Offset; right: Offset };
  offset: {
    front: Offset;
    back?: Offset;
    left?: Offset;
    right?: Offset;
    label?: Offset;
  };

  // in offsetWoven, the offset is what we divide the stageWidth(stageHeight) by
  // eg: [2.6, 3.45] -> x = stageWidth / 2.6 - labelWidth / 2, y = stageHeight / 3.45
  offsetWovenZoomed: Offset;
  scaleValueZoomed: number;

  offsetWovenNormal: Offset;
  scaleValueNormal: number; // < scaleValueZoomed
  // extraOffsetBelowNeckTape: number; // (in px) only in the y direction
  sizeDimensions?: ProductSizeDimensions;
  sizeChartImage: string;
  imageDimensions?: ImageDimensions;
  defaultEmbellishments?: DefaultEmbellishments;
  zoomedImage?: string;
  illustrationImage?: string;
  images: {
    front: string;
    back: string;
    right?: string;
    left?: string;
    label?: string;
  };
  frontIcon?: string;
  backIcon?: string;
  rightIcon?: string;
  leftIcon?: string;
  labelIcon?: string;
  heartIcon?: string;
  chestIcon?: string;
  frontPrintIcon?: string;
  backPrintIcon?: string;
  rightPrintIcon?: string;
  leftPrintIcon?: string;
  sizeWeights: Record<string, number>;
  predevelopedColorsConfig: ColorsPricing;
}

interface ProductsConfig {
  [key: string]: ProductConfig;
}

const addNeckOffset = add(5);

export const productsConfig: ProductsConfig = {
  // CLASSIC TEE
  AS5026: {
    canvaDimensions: { front: [78.31, 78.31], back: [78.31, 78.31], label: [31.324, 31.324] }, // cm
    style: productStyleType.Custom5026,
    neckSeamPosition: { front: [0, 11.36], back: [0, 4.78] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 15.62],
        back: [0, 7.83],
      };
    },

    offsetWovenZoomed: [0, 5.4],
    scaleValueZoomed: 0.825,

    offsetWovenNormal: [0, 4.8],
    scaleValueNormal: 0.8,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5026-front.png",
      back: "/PNGs/v2/5026-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5026-label.png",
    illustrationImage: "/products/db/v2/tshirt-tight.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "White", hex: "#FFFFFF", contrast: 5, category: "pre-developed", pantone: "", default: true },
        { name: "Bone", hex: "#D0CDCB", contrast: 25, category: "pre-developed", pantone: "" },
        { name: "Ecru", hex: "#E3E0D5", contrast: 5, category: "pre-developed", pantone: "" },
        { name: "Butter", hex: "#F3F0D9", contrast: 5, category: "pre-developed", pantone: "" },
        { name: "Sunset", hex: "#EEC777", contrast: 15, category: "pre-developed", pantone: "" },
        { name: "Yellow", hex: "#E0C259", contrast: 25, category: "pre-developed", pantone: "" },
        { name: "Orchid", hex: "#E2DDE3", contrast: 10, category: "pre-developed", pantone: "" },
        { name: "Lavender", hex: "#CCB2C4", contrast: 15, category: "pre-developed", pantone: "" },
        { name: "Violet", hex: "#523E7A", contrast: 20, category: "pre-developed", pantone: "" },
        { name: "Pale Pink", hex: "#EFCBB6", contrast: 15, category: "pre-developed", pantone: "" },
        { name: "Hazy Pink", hex: "#BC9285", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Autumn", hex: "#AF4C30", contrast: 15, category: "pre-developed", pantone: "" },
        { name: "Red", hex: "#A72A1E", contrast: 10, category: "pre-developed", pantone: "" },
        { name: "Cardinal", hex: "#772425", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Watermelon", hex: "#B95562", contrast: 20, category: "pre-developed", pantone: "" },
        { name: "Berry", hex: "#824854", contrast: 5, category: "pre-developed", pantone: "" },
        { name: "Burgundy", hex: "#4B2325", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Plum", hex: "#413338", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Sand", hex: "#C8BC92", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Khaki", hex: "#A8916D", contrast: 15, category: "pre-developed", pantone: "" },
        { name: "Camel", hex: "#9F7D47", contrast: 15, category: "pre-developed", pantone: "" },
        { name: "Cocoa", hex: "#80604C", contrast: 15, category: "pre-developed", pantone: "" },
        { name: "Clay", hex: "#805937", contrast: 25, category: "pre-developed", pantone: "" },
        { name: "Walnut", hex: "#685B4C", contrast: 15, category: "pre-developed", pantone: "" },
        { name: "Chestnut", hex: "#4A352B", contrast: 10, category: "pre-developed", pantone: "" },
        { name: "Lime", hex: "#DBDDAA", contrast: 15, category: "pre-developed", pantone: "" },
        { name: "Eucalyptus", hex: "#928F74", contrast: 15, category: "pre-developed", pantone: "" },
        { name: "Safari", hex: "#9E997E", contrast: 10, category: "pre-developed", pantone: "" },
        { name: "Pistachio", hex: "#B3B79C", contrast: 10, category: "pre-developed", pantone: "" },
        { name: "Moss", hex: "#8B874E", contrast: 15, category: "pre-developed", pantone: "" },
        { name: "Cypress", hex: "#53594B", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Army", hex: "#4D4A38", contrast: 5, category: "pre-developed", pantone: "" },
        { name: "Jade", hex: "#1B3F37", contrast: 20, category: "pre-developed", pantone: "" },
        { name: "Emerald", hex: "#203E32", contrast: 20, category: "pre-developed", pantone: "" },
        { name: "Atlantic", hex: "#27525A", contrast: 20, category: "pre-developed", pantone: "" },
        { name: "Pine Green", hex: "#18271F", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Lagoon", hex: "#C4DCDA", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pale Blue", hex: "#B5C3C7", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Mineral", hex: "#81918C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Storm", hex: "#A5A9A5", contrast: 30, category: "pre-developed", pantone: "" },
        { name: "Smoke", hex: "#9AA0A5", contrast: 10, category: "pre-developed", pantone: "" },
        { name: "Charlotte", hex: "#36788C", contrast: 25, category: "pre-developed", pantone: "" },
        { name: "Powder", hex: "#9A9FB0", contrast: 30, category: "pre-developed", pantone: "" },
        { name: "Ocean", hex: "#618FC2", contrast: 35, category: "pre-developed", pantone: "" },
        { name: "Lapis", hex: "#6B72A5", contrast: 15, category: "pre-developed", pantone: "" },
        { name: "Bright Royal", hex: "#425690", contrast: 35, category: "pre-developed", pantone: "" },
        { name: "Cobalt", hex: "#242B4F", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Petrol Blue", hex: "#424A53", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Midnight Blue", hex: "#36374B", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Navy", hex: "#1F202B", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "White Heather", hex: "#E4E4E4", contrast: 10, category: "pre-developed", pantone: "" },
        { name: "Athletic Heather", hex: "#A3A3A1", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Coal", hex: "#454445", contrast: 40, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#212121", contrast: 25, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // BLOCK SAFETY T SHIRT
  AS5050F: {
    canvaDimensions: { front: [78.64, 78.64], back: [78.32, 78.32], label: [32.13, 32.13] }, // cm
    style: productStyleType.Custom5050F,
    neckSeamPosition: { front: [0, 10.46], back: [0, 4.31] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 13.61],
        back: [0, 8.62],
      };
    },

    offsetWovenZoomed: [0, 5.4],
    scaleValueZoomed: 0.825,

    offsetWovenNormal: [0, 4.8],
    scaleValueNormal: 0.8,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5050F-front.png",
      back: "/PNGs/v2/5050F-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5050F-label.png",
    illustrationImage: "/products/db/v2/tshirt-tight.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Safety Yellow", hex: "#FCFF00", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Safety Orange", hex: "#FF6B07", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Safety Pink", hex: "#F535AA", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // WOMENS BASIC TEE
  AS4051: {
    canvaDimensions: { front: [66.87, 66.87], back: [66.58, 66.58], label: [32.13, 32.13] }, // cm
    style: productStyleType.Custom4051,
    neckSeamPosition: { front: [0, 10.36], back: [0, 4.26] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 12.57],
        back: [0, 9.99],
      };
    },

    offsetWovenZoomed: [0, 4.85],
    scaleValueZoomed: 1,

    offsetWovenNormal: [0, 4],
    scaleValueNormal: 0.8,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/4051-front.png",
      back: "/PNGs/v2/4051-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/4051-label.png",
    illustrationImage: "/products/db/v2/tshirt-tight.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "White", hex: "#FFFFFF", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Natural", hex: "#FBF7EE", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Bone", hex: "#D1CDCA", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pale Pink", hex: "#F6D5C6", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Red", hex: "#BB1016", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Burgundy", hex: "#512024", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Sage", hex: "#768D7D", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Army", hex: "#4E4A36", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pale Blue", hex: "#B1C3C7", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Slate Blue", hex: "#74929A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Navy", hex: "#1E202C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Athletic Heather", hex: "#A4A4A2", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Coal", hex: "#323031", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#1e1e1e", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // WOMENS CROP TEE
  AS4062: {
    canvaDimensions: { front: [65.83, 65.83], back: [65.66, 65.66], label: [32.16, 32.16] }, // cm
    style: productStyleType.Custom4062,
    neckSeamPosition: { front: [0, 18.57], back: [0, 12.87] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 19.82],
        back: [0, 16.09],
      };
    },

    offsetWovenZoomed: [0, 6.8],
    scaleValueZoomed: 0.9,

    offsetWovenNormal: [0, 12.75],
    scaleValueNormal: 0.7,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/4062-front.png",
      back: "/PNGs/v2/4062-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/4062-label.png",
    illustrationImage: "/products/db/v2/tshirt-tight.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "White", hex: "#FFFFFF", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Natural", hex: "#FBF7EE", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Butter", hex: "#F4F0D7", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Ecru", hex: "#E4E0D4", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pale Pink", hex: "#F6D5C6", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Hazy Pink", hex: "#BD9386", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Bubblegum", hex: "#EBA3B1", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Red", hex: "#BB1016", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Seafoam", hex: "#E6F3F4", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Sage", hex: "#768D7D", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Mineral", hex: "#7E958D", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Orchid", hex: "#E3DDE3", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Carolina Blue", hex: "#A0BFE2", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Powder", hex: "#989FB1", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Navy", hex: "#1E202C", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Bone", hex: "#D1CDCA", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Athletic Heather", hex: "#A4A4A2", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Storm", hex: "#A4ABA7", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Coal", hex: "#323031", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#1e1e1e", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // WOMENS CLASSIC TEE
  AS4026: {
    canvaDimensions: { front: [74.6, 74.6], back: [73.91, 73.91], label: [32.1, 32.1] }, // cm
    style: productStyleType.Custom4026,
    neckSeamPosition: { front: [0, 11.94], back: [0, 6.28] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 14.77],
        back: [0, 9.61],
      };
    },

    offsetWovenZoomed: [0, 6.1],
    scaleValueZoomed: 0.8,

    offsetWovenNormal: [0, 5.5],
    scaleValueNormal: 0.8,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/4026-front.png",
      back: "/PNGs/v2/4026-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/4026-label.png",
    illustrationImage: "/products/db/v2/tshirt-tight.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "White", hex: "#FFFFFF", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Bone", hex: "#D1CDCA", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Athletic Heather", hex: "#A4A4A2", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Butter", hex: "#F4F0D7", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Ecru", hex: "#E4E0D4", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Sand", hex: "#B7B09D", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Eucalyptus", hex: "#938E71", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Storm", hex: "#A4ABA7", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Powder", hex: "#989FB1", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Orchid", hex: "#E3DDE3", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Hazy Pink", hex: "#BD9386", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Chestnut", hex: "#4E332A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Navy", hex: "#1E202C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#1e1e1e", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // HEAVY CREW
  AS5145: {
    canvaDimensions: { front: [88.05, 88.05], back: [87.63, 87.63], label: [32.13, 32.13] }, // cm
    style: productStyleType.Custom5145,
    neckSeamPosition: { front: [0, 15.94], back: [0, 10.78] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 18.84],
        back: [0, 15.51],
      };
    },

    offsetWovenZoomed: [0, 6.7],
    scaleValueZoomed: 0.5,

    offsetWovenNormal: [0, 10.2],
    scaleValueNormal: 0.6,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5145-front.png",
      back: "/PNGs/v2/5145-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5145-label.png",
    illustrationImage: "/products/db/v2/classic-longsleeves.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Ecru", hex: "#E4E0D4", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Bone", hex: "#D1CDCA", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Sand", hex: "#B7B09D", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Eucalyptus", hex: "#938E71", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pine Green", hex: "#1D342A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Midnight Blue", hex: "#35374C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Athletic Heather", hex: "#A4A4A2", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Coal", hex: "#323031", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#1e1e1e", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // RELAX CREW
  AS5160: {
    canvaDimensions: { front: [84.46, 84.46], back: [84.25, 84.25], label: [32.23, 32.23] }, // cm
    style: productStyleType.Custom5160,
    neckSeamPosition: { front: [0, 13.94], back: [0, 9.27] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 16.3],
        back: [0, 13.73],
      };
    },

    offsetWovenZoomed: [0, 6.7],
    scaleValueZoomed: 0.5,

    offsetWovenNormal: [0, 9.75],
    scaleValueNormal: 0.6,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5145-front.png",
      back: "/PNGs/v2/5145-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5145-label.png",
    illustrationImage: "/products/db/v2/classic-longsleeves.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Bone", hex: "#D1CDCA", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Butter", hex: "#F4F0D7", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Sand", hex: "#B7B09D", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Clay", hex: "#875632", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Chestnut", hex: "#4E332A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Powder", hex: "#989FB1", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Cobalt", hex: "#212B51", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Cypress", hex: "#51594A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pine Green", hex: "#1D342A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "White Heather", hex: "#E4E4E6", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Athletic Heather", hex: "#A4A4A2", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#1e1e1e", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // SUPPLY HOODIE
  AS5101: {
    canvaDimensions: { front: [104.7, 104.7], back: [103.85, 103.85], label: [32.23, 32.23] }, // cm
    style: productStyleType.Custom5101,
    neckSeamPosition: { front: [0, 13.94], back: [0, 9.27] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 38.01],
        back: [0, 38.63],
      };
    },

    offsetWovenZoomed: [0, 6.7],
    scaleValueZoomed: 0.5,

    offsetWovenNormal: [0, 9.75],
    scaleValueNormal: 0.6,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5101-front.png",
      back: "/PNGs/v2/5101-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5101-label.png",
    illustrationImage: "/products/db/v2/hoodie-oversized.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "White", hex: "#FFFFFF", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Ecru", hex: "#E4E0D4", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Bone", hex: "#D1CDCA", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pink", hex: "#F0D2D4", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Gold", hex: "#F8AC32", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Orange", hex: "#E56929", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Red", hex: "#BB1016", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Forest Green", hex: "#355138", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Army", hex: "#4E4A36", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Navy", hex: "#1E202C", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "White Heather", hex: "#E4E4E6", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Athletic Heather", hex: "#A4A4A2", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Asphalt Heather", hex: "#50504B", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Coal", hex: "#323031", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#1e1e1e", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // HEAVY HOODIE
  AS5146: {
    canvaDimensions: { front: [105.39, 105.39], back: [106.43, 106.43], label: [32.23, 32.23] }, // cm
    style: productStyleType.Custom5146,
    neckSeamPosition: { front: [0, 34.57], back: [0, 34.48] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 37.73],
        back: [0, 38],
      };
    },

    offsetWovenZoomed: [0, 6.7],
    scaleValueZoomed: 0.5,

    offsetWovenNormal: [0, 9.75],
    scaleValueNormal: 0.6,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5146-front.png",
      back: "/PNGs/v2/5146-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5146-label.png",
    illustrationImage: "/products/db/v2/hoodie-oversized.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Ecru", hex: "#E4E0D4", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Bone", hex: "#D1CDCA", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Sand", hex: "#B7B09D", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Eucalyptus", hex: "#938E71", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pine Green", hex: "#1D342A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Midnight Blue", hex: "#35374C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Athletic Heather", hex: "#A4A4A2", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Coal", hex: "#323031", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#1e1e1e", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // STENCIL SAFETY HOODIE
  AS5102F: {
    canvaDimensions: { front: [104.98, 104.98], back: [103.21, 103.21], label: [32.23, 32.23] }, // cm
    style: productStyleType.Custom5102F,
    neckSeamPosition: { front: [0, 35.59], back: [0, 27.87] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 38],
        back: [0, 33.65],
      };
    },

    offsetWovenZoomed: [0, 6.7],
    scaleValueZoomed: 0.5,

    offsetWovenNormal: [0, 9.75],
    scaleValueNormal: 0.6,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5102F-front.png",
      back: "/PNGs/v2/5102F-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5102F-label.png",
    illustrationImage: "/products/db/v2/hoodie-oversized.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Safety Yellow", hex: "#EFF162", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Safety Orange", hex: "#FF6B07", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // CLASSIC TANK TOP
  AS5073: {
    canvaDimensions: { front: [79.74, 79.74], back: [79.26, 79.26], label: [32.29, 32.29] }, // cm
    style: productStyleType.Custom5073,
    neckSeamPosition: { front: [0, 11.56], back: [0, 4.84] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 13.87],
        back: [0, 9.43],
      };
    },

    offsetWovenZoomed: [0, 5.1],
    scaleValueZoomed: 0.85,

    offsetWovenNormal: [0, 5],
    scaleValueNormal: 0.85,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5073-front.png",
      back: "/PNGs/v2/5073-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5073-label.png",
    illustrationImage: "/products/db/v2/tshirt-tight.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "White", hex: "#FFFFFF", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Ecru", hex: "#E4E0D4", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Bone", hex: "#D1CDCA", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Clay", hex: "#875632", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Cypress", hex: "#51594A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Navy", hex: "#1E202C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#1e1e1e", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // PIQUE POLO
  AS5411: {
    canvaDimensions: { front: [83.33, 83.33], back: [79.26, 79.26], label: [32.29, 32.29] }, // cm
    style: productStyleType.Custom5411,
    neckSeamPosition: { front: [0, 10.17], back: [0, 3.9] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [11, 13.67],
        back: [0, 8.85],
      };
    },

    offsetWovenZoomed: [0.25, 5.4],
    scaleValueZoomed: 0.65,

    offsetWovenNormal: [0.25, 5.5],
    scaleValueNormal: 0.7,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5411-front.png",
      back: "/PNGs/v2/5411-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5411-label.png",
    illustrationImage: "/products/db/v2/tshirt-tight.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "White", hex: "#FFFFFF", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Navy", hex: "#1E202C", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Athletic Heather", hex: "#A4A4A2", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#1e1e1e", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // CHAD POLO
  AS5402: {
    canvaDimensions: { front: [80.12, 80.12], back: [81.59, 81.59], label: [32.16, 32.16] }, // cm
    style: productStyleType.Custom5402,
    neckSeamPosition: { front: [0, 12.26], back: [0, 3.92] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [11, 14.66],
        back: [0, 8.97],
      };
    },

    offsetWovenZoomed: [0, 4.25],
    scaleValueZoomed: 0.8,

    offsetWovenNormal: [0, 4.5],
    scaleValueNormal: 0.7,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5402-front.png",
      back: "/PNGs/v2/5402-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5402-label.png",
    illustrationImage: "/products/db/v2/tshirt-tight.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "White", hex: "#FFFFFF", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Athletic Heather", hex: "#A4A4A2", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Army", hex: "#4E4A36", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Navy", hex: "#1E202C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Coal", hex: "#323031", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#1e1e1e", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // HEAVY LONG SLEEVE
  AS5081: {
    canvaDimensions: { front: [85.67, 85.67], back: [85.58, 85.58], label: [32.23, 32.23] }, // cm
    style: productStyleType.Custom5081,
    neckSeamPosition: { front: [0, 14.99], back: [0, 8.3] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 18.25],
        back: [0, 14.38],
      };
    },

    offsetWovenZoomed: [0, 6.5],
    scaleValueZoomed: 0.65,

    offsetWovenNormal: [0, 8.85],
    scaleValueNormal: 0.65,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5081-front.png",
      back: "/PNGs/v2/5081-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5081-label.png",
    illustrationImage: "/products/db/v2/classic-longsleeves.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "White", hex: "#FFFFFF", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Butter", hex: "#F4F0D7", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Ecru", hex: "#E4E0D4", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Clay", hex: "#875632", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Cypress", hex: "#51594A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Midnight Blue", hex: "#35374C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#1e1e1e", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // BLOCK SAFETY LONG SLEEVE
  AS5054F: {
    canvaDimensions: { front: [82.98, 82.98], back: [82.7, 82.7], label: [36.29, 36.29] }, // cm
    style: productStyleType.Custom5054F,
    neckSeamPosition: { front: [0, 9.46], back: [0, 4.71] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 13.61],
        back: [0, 10.42],
      };
    },

    offsetWovenZoomed: [0, 5.5],
    scaleValueZoomed: 0.65,

    offsetWovenNormal: [0, 5],
    scaleValueNormal: 0.65,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5054F-front.png",
      back: "/PNGs/v2/5054F-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5054F-label.png",
    illustrationImage: "/products/db/v2/classic-longsleeves.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Safety Yellow", hex: "#EFF162", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Safety Orange", hex: "#FF6B07", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // GENERAL LONG SLEEVE
  AS5056: {
    canvaDimensions: { front: [80.27, 80.27], back: [81.58, 81.58], label: [32.29, 32.29] }, // cm
    style: productStyleType.Custom5056,
    neckSeamPosition: { front: [0, 12.28], back: [0, 5.38] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 14.77],
        back: [0, 11.18],
      };
    },

    offsetWovenZoomed: [0, 5.5],
    scaleValueZoomed: 0.8,

    offsetWovenNormal: [0, 5.5],
    scaleValueNormal: 0.8,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5056-front.png",
      back: "/PNGs/v2/5056-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5056-label.png",
    illustrationImage: "/products/db/v2/classic-longsleeves.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "White", hex: "#FFFFFF", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Gold", hex: "#F8AC32", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Red", hex: "#BB1016", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Navy", hex: "#1E202C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#1e1e1e", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // HEAVY TEE
  AS5080: {
    canvaDimensions: { front: [98.33, 98.33], back: [97.84, 97.84], label: [31.25, 31.25] }, // cm
    style: productStyleType.Custom5080,
    neckSeamPosition: { front: [0, 16.22], back: [0, 8.02] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 19.96],
        back: [0, 14.38],
      };
    },

    offsetWovenZoomed: [0, 6],
    scaleValueZoomed: 0.8,

    offsetWovenNormal: [0, 7.85],
    scaleValueNormal: 0.8,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5080-front.png",
      back: "/PNGs/v2/5080-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5080-label.png",
    illustrationImage: "/products/db/v2/tshirt-tight.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "White", hex: "#FFFFFF", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Orchid", hex: "#E3DDE3", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Ecru", hex: "#E4E0D4", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Butter", hex: "#F4F0D7", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pistachio", hex: "#B2B799", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Eucalyptus", hex: "#938E71", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Cypress", hex: "#51594A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pine Green", hex: "#1D342A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Walnut", hex: "#6B5A4A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Clay", hex: "#875632", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Red", hex: "#BB1016", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Plum", hex: "#433238", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Midnight Blue", hex: "#35374C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Navy", hex: "#1E202C", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Athletic Heather", hex: "#A4A4A2", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#1e1e1e", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // BASIC TEE
  AS5051: {
    canvaDimensions: { front: [80.53, 80.53], back: [80.6, 80.6], label: [32.1, 32.1] }, // cm
    style: productStyleType.Custom5051,
    neckSeamPosition: { front: [0, 11.76], back: [0, 5.32] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 14.5],
        back: [0, 9.35],
      };
    },

    offsetWovenZoomed: [0, 5],
    scaleValueZoomed: 0.8,

    offsetWovenNormal: [0, 5],
    scaleValueNormal: 0.8,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5051-front.png",
      back: "/PNGs/v2/5051-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5051-label.png",
    illustrationImage: "/products/db/v2/tshirt-tight.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "White", hex: "#FFFFFF", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Natural", hex: "#FBF7EE", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Butter", hex: "#F4F0D7", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Lemonade", hex: "#EADE8C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Mustard", hex: "#E7AD52", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pale Pink", hex: "#F6D5C6", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Orchid", hex: "#E3DDE3", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Watermelon", hex: "#C84B61", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Red", hex: "#BB1016", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Copper", hex: "#88472D", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Burgundy", hex: "#512024", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Khaki", hex: "#AD8F69", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Seafoam", hex: "#E6F3F4", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Lime", hex: "#DDE0A7", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Eucalyptus", hex: "#938E71", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Sage", hex: "#768D7D", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Mineral", hex: "#7E958D", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Army", hex: "#4E4A36", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Carolina Blue", hex: "#A0BFE2", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Bright Royal", hex: "#395793", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Petrol Blue", hex: "#404A53", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Navy", hex: "#1E202C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Bone", hex: "#D1CDCA", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Granite", hex: "#A1A0A5", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Athletic Heather", hex: "#A4A4A2", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Coal", hex: "#323031", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#1e1e1e", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // STAPLE TEE
  AS5001: {
    canvaDimensions: { front: [80.44, 80.44], back: [80.45, 80.45], label: [32.13, 32.13] }, // cm
    style: productStyleType.Custom5001,
    neckSeamPosition: { front: [0, 11.74], back: [0, 5.23] },
    get offset() {
      // const frontNeckY = this.neckSeamPosition?.front[1] as number;
      // const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, 15.44],
        back: [0, 9.73],
      };
    },

    offsetWovenZoomed: [0, 5],
    scaleValueZoomed: 0.8,

    offsetWovenNormal: [0, 5],
    scaleValueNormal: 0.8,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    images: {
      front: "/PNGs/v2/5001-front.png",
      back: "/PNGs/v2/5001-back.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/5001-label.png",
    illustrationImage: "/products/db/v2/tshirt-tight.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "White", hex: "#FFFFFF", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Natural", hex: "#FBF7EE", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Ecru", hex: "#E4E0D4", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Lemonade", hex: "#EADE8C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Lemon", hex: "#F3E58D", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Yellow", hex: "#E8C045", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Sunset", hex: "#F7C46C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Mustard", hex: "#E7AD52", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Gold", hex: "#F8AC32", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Orange", hex: "#E56929", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Autumn", hex: "#BE4228", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Fire", hex: "#D2201C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pale Pink", hex: "#F6D5C6", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pink", hex: "#F0D2D4", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Bubblegum", hex: "#EBA3B1", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Charity Pink", hex: "#D56793", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Berry", hex: "#8B4354", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Coral", hex: "#9A5A53", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Clay", hex: "#875632", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Copper", hex: "#88472D", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Red", hex: "#BB1016", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Cardinal", hex: "#821822", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Burgundy", hex: "#512024", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Plum", hex: "#433238", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Purple", hex: "#603E71", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Mauve", hex: "#86727B", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pale Blue", hex: "#B1C3C7", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Seafoam", hex: "#E6F3F4", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Slate Blue", hex: "#74929A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Smoke", hex: "#98A0A5", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Powder", hex: "#989FB1", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Carolina Blue", hex: "#A0BFE2", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Arctic Blue", hex: "#2C78AC", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Hydro", hex: "#3D81AE", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Lapis", hex: "#6772A8", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Bright Royal", hex: "#395793", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Cobalt", hex: "#212B51", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Petrol Blue", hex: "#404A53", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Midnight Blue", hex: "#35374C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Navy", hex: "#1E202C", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Citrus", hex: "#BDEA73", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Lime", hex: "#DDE0A7", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pistachio", hex: "#B2B799", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Safari", hex: "#9F987B", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Eucalyptus", hex: "#938E71", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Moss", hex: "#8D8646", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Sage", hex: "#768D7D", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Mineral", hex: "#7E958D", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Topaz", hex: "#58AE95", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Charlotte", hex: "#007A8E", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Atlantic", hex: "#10535B", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Kelly Green", hex: "#027D4F", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Cypress", hex: "#51594A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Army", hex: "#4E4A36", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Jade", hex: "#084037", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Forest Green", hex: "#355138", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Pine Green", hex: "#1D342A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Dark Chocolate", hex: "#3C2D26", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Chestnut", hex: "#4E332A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Walnut", hex: "#6B5A4A", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Camel", hex: "#A67A3F", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Tan", hex: "#C6A379", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Khaki", hex: "#AD8F69", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Cocoa", hex: "#865E49", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Mushroom", hex: "#B2A795", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Sand", hex: "#B7B09D", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Bone", hex: "#D1CDCA", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Light Grey", hex: "#B9B5AC", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Athletic Heather", hex: "#A4A4A2", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Granite", hex: "#A1A0A5", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Charcoal", hex: "#464646", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Coal", hex: "#323031", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Black", hex: "#1e1e1e", contrast: 0, category: "pre-developed", pantone: "", default: true },
        { name: "Kiwi", hex: "#849161", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Orchid", hex: "#E3DDE3", contrast: 0, category: "pre-developed", pantone: "" },
        { name: "Butter", hex: "#F4F0D7", contrast: 0, category: "pre-developed", pantone: "" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // T-shirt 180 slim fit
  db180ts: {
    canvaDimensions: { front: [90, 90], back: [90, 90], label: [90, 29.4] }, // cm
    style: productStyleType.TShirt,
    neckSeamPosition: { front: [0, 14.5], back: [0, 6] },
    get offset() {
      const frontNeckY = this.neckSeamPosition?.front[1] as number;
      const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, addNeckOffset(frontNeckY)],
        back: [0, addNeckOffset(backNeckY)],
      };
    },

    offsetWovenZoomed: [0.25, 10.5],
    scaleValueZoomed: 1.6,

    offsetWovenNormal: [0, 5.5],
    scaleValueNormal: 1,

    sizeChartImage: "/size-charts/tshirt-regular-updated.png",
    imageDimensions: {
      width: 878,
      height: 792,
      leftChest: {
        width: 111,
        height: 66,
        x: 502,
        y: 211,
      },
    },

    sizeDimensions: {
      length: [
        { size: "XS", dimension: 67 },
        { size: "S", dimension: 69 },
        { size: "M", dimension: 71 },
        { size: "L", dimension: 73.5 },
        { size: "XL", dimension: 76 },
        { size: "2XL", dimension: 78.5 },
      ],
      chest: [
        { size: "XS", dimension: 52 },
        { size: "S", dimension: 54 },
        { size: "M", dimension: 56 },
        { size: "L", dimension: 58 },
        { size: "XL", dimension: 61.5 },
        { size: "2XL", dimension: 65 },
      ],
      sleeve: [
        { size: "XS", dimension: 20.8 },
        { size: "S", dimension: 21.3 },
        { size: "M", dimension: 21.8 },
        { size: "L", dimension: 22.3 },
        { size: "XL", dimension: 23.3 },
        { size: "2XL", dimension: 24.3 },
      ],
    },

    defaultEmbellishments: {
      digitalPrint: true,
      screenPrint: false,
      embroidery: false,
      heatTransfer: false,
    },
    images: {
      front: "/PNGs/v2/classic-tshirt-front-updated.png",
      back: "/PNGs/v2/classic-tshirt-back-updated.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/classic-tshirt-zoomed-updated.png",
    illustrationImage: "/products/db/v2/tshirt-tight.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Black 6 C", hex: "#1e1e1e", pantone: null, category: "pre-developed" },
        { name: "Volcanic Ash", hex: "#606166", pantone: "19-3912 TXC", category: "pre-developed" },
        { name: "Mulled Grape", hex: "#675a74", pantone: "18-3714 TCX", category: "pre-developed" },
        { name: "Tapioca", hex: "#ddceba", pantone: "12-1403 TCX", category: "pre-developed" },
        { name: "Deep Taupe", hex: "#65534f", pantone: "18-1312 TCX", category: "pre-developed" },
        { name: "Demitasse", hex: "#40332b", pantone: "19-0712 TCX", category: "pre-developed" },
        { name: "High-rise", hex: "#aab1b3", pantone: "15-4101 TCX", category: "pre-developed" },
        { name: "Shadow", hex: "#899187", pantone: "17-6206 TPX", category: "pre-developed" },
        { name: "Bright White", hex: "#f4f9ff", pantone: "11-0601 TCX", category: "pre-developed", default: true },
        { name: "Olive Branch", hex: "#616141", pantone: "18-0527 TCX", category: "pre-developed" },
        { name: "Stratified Sea", hex: "#3b4853", pantone: "19-4112 TCX", category: "pre-developed" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // T-shirt 300 boxy fit
  db300tb: {
    canvaDimensions: { front: [90, 90], back: [90, 90], label: [90, 29.4] }, // cm
    style: productStyleType.TShirt,
    neckSeamPosition: { front: [0, 21], back: [0, 11.5] },
    get offset() {
      const frontNeckY = this.neckSeamPosition?.front[1] as number;
      const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, addNeckOffset(frontNeckY)],
        back: [0, addNeckOffset(backNeckY)],
      };
    },
    offsetWovenZoomed: [0, 9],
    scaleValueZoomed: 1.15,

    offsetWovenNormal: [0.5, 14.5],
    scaleValueNormal: 0.8,

    imageDimensions: {
      width: 1100,
      height: 838,
      leftChest: {
        width: 122,
        height: 72,
        x: 613,
        y: 250,
      },
    },
    sizeChartImage: "/size-charts/tshirt-boxy-updated.png",
    sizeDimensions: {
      length: [
        { size: "XS", dimension: 27.5 },
        { size: "S", dimension: 28.5 },
        { size: "M", dimension: 29.5 },
        { size: "L", dimension: 30.5 },
        { size: "XL", dimension: 31.5 },
        { size: "2XL", dimension: 32.5 },
      ],
      chest: [
        { size: "XS", dimension: 18.5 },
        { size: "S", dimension: 20 },
        { size: "M", dimension: 21.5 },
        { size: "L", dimension: 22.5 },
        { size: "XL", dimension: 24.5 },
        { size: "2XL", dimension: 26.5 },
      ],
      sleeve: [
        { size: "XS", dimension: 7.25 },
        { size: "S", dimension: 7.75 },
        { size: "M", dimension: 8.25 },
        { size: "L", dimension: 8.5 },
        { size: "XL", dimension: 8.75 },
        { size: "2XL", dimension: 9.25 },
      ],
    },

    defaultEmbellishments: {
      digitalPrint: true,
      screenPrint: false,
      embroidery: false,
      heatTransfer: false,
    },
    images: {
      front: "/PNGs/v2/boxy-tshirt-front-updated.png",
      back: "/PNGs/v2/boxy-tshirt-back-updated.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/boxy-tshirt-zoomed.png",
    // zoomedImage: "/PNGs/ZoomedImages/zoomed-boxy-t-shirt-200.png",
    illustrationImage: "/products/db/v2/tshirt-boxy.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },
    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Black 6 C", hex: "#1e1e1e", pantone: null, category: "pre-developed" },
        { name: "Volcanic Ash", hex: "#606166", pantone: "19-3912 TXC", category: "pre-developed" },
        { name: "Mulled Grape", hex: "#675a74", pantone: "18-3714 TCX", category: "pre-developed" },
        { name: "Tapioca", hex: "#ddceba", pantone: "12-1403 TCX", category: "pre-developed", default: true },
        { name: "Deep Taupe", hex: "#65534f", pantone: "18-1312 TCX", category: "pre-developed" },
        { name: "Demitasse", hex: "#40332b", pantone: "19-0712 TCX", category: "pre-developed" },
        { name: "High-rise", hex: "#aab1b3", pantone: "15-4101 TCX", category: "pre-developed" },
        { name: "Shadow", hex: "#899187", pantone: "17-6206 TPX", category: "pre-developed" },
        { name: "Bright White", hex: "#f4f9ff", pantone: "11-0601 TCX", category: "pre-developed" },
        { name: "Olive Branch", hex: "#616141", pantone: "18-0527 TCX", category: "pre-developed" },
        { name: "Stratified Sea", hex: "#3b4853", pantone: "19-4112 TCX", category: "pre-developed" },
        { name: "PFD", hex: "#fffaeb", pantone: "PFD", category: "pre-developed" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // T-shirt 180 regular fit
  db180tr: {
    canvaDimensions: { front: [90, 90], back: [90, 90], label: [90, 29.4] }, // cm
    style: productStyleType.TShirt,
    neckSeamPosition: { front: [0, 14.5], back: [0, 6] },
    get offset() {
      const frontNeckY = this.neckSeamPosition?.front[1] as number;
      const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, addNeckOffset(frontNeckY)],
        back: [0, addNeckOffset(backNeckY)],
      };
    },

    offsetWovenZoomed: [0.25, 10.75],
    scaleValueZoomed: 1.6,

    offsetWovenNormal: [0, 5.5],
    scaleValueNormal: 1,
    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    imageDimensions: {
      width: 878,
      height: 792,
      leftChest: {
        width: 111,
        height: 66,
        x: 470,
        y: 225,
      },
    },

    sizeDimensions: {
      length: [
        { size: "XS", dimension: 30.5 },
        { size: "S", dimension: 31.5 },
        { size: "M", dimension: 32.5 },
        { size: "L", dimension: 33.5 },
        { size: "XL", dimension: 34.5 },
        { size: "2XL", dimension: 35.5 },
      ],
      chest: [
        { size: "XS", dimension: 18 },
        { size: "S", dimension: 20 },
        { size: "M", dimension: 22.5 },
        { size: "L", dimension: 23.5 },
        { size: "XL", dimension: 25.5 },
        { size: "2XL", dimension: 27.5 },
      ],
      sleeve: [
        { size: "XS", dimension: 8 },
        { size: "S", dimension: 8.5 },
        { size: "M", dimension: 9 },
        { size: "L", dimension: 9.5 },
        { size: "XL", dimension: 10 },
        { size: "2XL", dimension: 10.5 },
      ],
    },

    defaultEmbellishments: {
      digitalPrint: true,
      screenPrint: false,
      embroidery: false,
      heatTransfer: false,
    },
    // images: {
    //   front: "/PNGs/classicTShirt270Front.png",
    //   back: "/PNGs/classicTShirt270Back.png",
    // },
    // zoomedImage: "/PNGs/ZoomedImages/zoomed-classic-t-shirt-270.png",
    images: {
      front: "/PNGs/v2/classic-tshirt-front-updated.png",
      back: "/PNGs/v2/classic-tshirt-back-updated.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/classic-tshirt-zoomed-updated.png",
    illustrationImage: "/products/db/v2/tshirt-regular.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Black 6 C", hex: "#1e1e1e", pantone: null, category: "pre-developed" },
        { name: "Volcanic Ash", hex: "#606166", pantone: "19-3912 TXC", category: "pre-developed" },
        { name: "Mulled Grape", hex: "#675a74", pantone: "18-3714 TCX", category: "pre-developed" },
        { name: "Tapioca", hex: "#ddceba", pantone: "12-1403 TCX", category: "pre-developed" },
        { name: "Deep Taupe", hex: "#65534f", pantone: "18-1312 TCX", category: "pre-developed" },
        { name: "Demitasse", hex: "#40332b", pantone: "19-0712 TCX", category: "pre-developed" },
        { name: "High-rise", hex: "#aab1b3", pantone: "15-4101 TCX", category: "pre-developed" },
        { name: "Shadow", hex: "#899187", pantone: "17-6206 TPX", category: "pre-developed" },
        { name: "Bright White", hex: "#f4f9ff", pantone: "11-0601 TCX", category: "pre-developed", default: true },
        { name: "Olive Branch", hex: "#616141", pantone: "18-0527 TCX", category: "pre-developed" },
        { name: "Stratified Sea", hex: "#3b4853", pantone: "19-4112 TCX", category: "pre-developed" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // T-shirt 300 oversize fit
  db300to: {
    canvaDimensions: { front: [90, 90], back: [90, 90], label: [90, 29.4] }, // cm
    style: productStyleType.TShirt,
    neckSeamPosition: { front: [0, 20.5], back: [0, 10] },
    get offset() {
      const frontNeckY = this.neckSeamPosition?.front[1] as number;
      const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, addNeckOffset(frontNeckY)],
        back: [0, addNeckOffset(backNeckY)],
      };
    },

    offsetWovenZoomed: [-0.5, 8.75],
    scaleValueZoomed: 1.2,

    offsetWovenNormal: [-0.5, 13],
    scaleValueNormal: 0.8,
    sizeChartImage: "/size-charts/tshirt-regular-updated.png",

    imageDimensions: {
      width: 878,
      height: 792,
      leftChest: {
        width: 111,
        height: 66,
        x: 470,
        y: 225,
      },
    },

    sizeDimensions: {
      length: [
        { size: "XS", dimension: 30.5 },
        { size: "S", dimension: 31.5 },
        { size: "M", dimension: 32.5 },
        { size: "L", dimension: 33.5 },
        { size: "XL", dimension: 34.5 },
        { size: "2XL", dimension: 35.5 },
      ],
      chest: [
        { size: "XS", dimension: 18 },
        { size: "S", dimension: 20 },
        { size: "M", dimension: 22.5 },
        { size: "L", dimension: 23.5 },
        { size: "XL", dimension: 25.5 },
        { size: "2XL", dimension: 27.5 },
      ],
      sleeve: [
        { size: "XS", dimension: 8 },
        { size: "S", dimension: 8.5 },
        { size: "M", dimension: 9 },
        { size: "L", dimension: 9.5 },
        { size: "XL", dimension: 10 },
        { size: "2XL", dimension: 10.5 },
      ],
    },

    defaultEmbellishments: {
      digitalPrint: true,
      screenPrint: false,
      embroidery: false,
      heatTransfer: false,
    },
    // images: {
    //   front: "/PNGs/classicTShirt270Front.png",
    //   back: "/PNGs/classicTShirt270Back.png",
    // },
    // zoomedImage: "/PNGs/ZoomedImages/zoomed-classic-t-shirt-270.png",
    images: {
      front: "/PNGs/v2/oversized-tshirt-front-updated.png",
      back: "/PNGs/v2/oversized-tshirt-back-updated.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/oversized-tshirt-zoomed-updated.png",
    illustrationImage: "/products/db/v2/tshirt-regular.png",
    frontIcon: "/product-icons/tshirt-front.svg",
    backIcon: "/product-icons/tshirt-back.svg",
    labelIcon: "/product-icons/tshirt-label.svg",
    heartIcon: "/product-icons/tshirt-heart.svg",
    chestIcon: "/product-icons/tshirt-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Black 6 C", hex: "#1e1e1e", pantone: null, category: "pre-developed" },
        { name: "Volcanic Ash", hex: "#606166", pantone: "19-3912 TXC", category: "pre-developed" },
        { name: "Mulled Grape", hex: "#675a74", pantone: "18-3714 TCX", category: "pre-developed" },
        { name: "Tapioca", hex: "#ddceba", pantone: "12-1403 TCX", category: "pre-developed", default: true },
        { name: "Deep Taupe", hex: "#65534f", pantone: "18-1312 TCX", category: "pre-developed" },
        { name: "Demitasse", hex: "#40332b", pantone: "19-0712 TCX", category: "pre-developed" },
        { name: "High-rise", hex: "#aab1b3", pantone: "15-4101 TCX", category: "pre-developed" },
        { name: "Shadow", hex: "#899187", pantone: "17-6206 TPX", category: "pre-developed" },
        { name: "Bright White", hex: "#f4f9ff", pantone: "11-0601 TCX", category: "pre-developed" },
        { name: "Olive Branch", hex: "#616141", pantone: "18-0527 TCX", category: "pre-developed" },
        { name: "Stratified Sea", hex: "#3b4853", pantone: "19-4112 TCX", category: "pre-developed" },
        { name: "PFD", hex: "#fffaeb", pantone: "PFD", category: "pre-developed" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // Longsleeve 180
  db180lr: {
    canvaDimensions: { front: [90, 90], back: [90, 90], label: [90, 29.4] }, // cm
    style: productStyleType.LongSleeve,
    neckSeamPosition: { front: [0, 20.5], back: [0, 13] },
    get offset() {
      const frontNeckY = this.neckSeamPosition?.front[1] as number;
      const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, addNeckOffset(frontNeckY)],
        back: [0, addNeckOffset(backNeckY)],
      };
    },

    // offsetWovenZoomed: [1.5, 12.25],
    // scaleValueZoomed: 1.35,
    offsetWovenZoomed: [0, 10.5],
    scaleValueZoomed: 1.35,

    offsetWovenNormal: [0, 13],
    scaleValueNormal: 1,

    sizeChartImage: "/size-charts/classic-longsleeves-updated.png",

    imageDimensions: {
      width: 1100,
      height: 838,
      leftChest: {
        width: 122,
        height: 72,
        x: 613,
        y: 250,
      },
    },

    sizeDimensions: {
      length: [
        { size: "XS", dimension: 27.5 },
        { size: "S", dimension: 28.5 },
        { size: "M", dimension: 29.5 },
        { size: "L", dimension: 30.5 },
        { size: "XL", dimension: 31.5 },
        { size: "2XL", dimension: 32.5 },
      ],
      chest: [
        { size: "XS", dimension: 18.5 },
        { size: "S", dimension: 20 },
        { size: "M", dimension: 21.5 },
        { size: "L", dimension: 22.5 },
        { size: "XL", dimension: 24.5 },
        { size: "2XL", dimension: 26.5 },
      ],
      sleeve: [
        { size: "XS", dimension: 7.25 },
        { size: "S", dimension: 7.75 },
        { size: "M", dimension: 8.25 },
        { size: "L", dimension: 8.5 },
        { size: "XL", dimension: 8.75 },
        { size: "2XL", dimension: 9.25 },
      ],
    },

    defaultEmbellishments: {
      digitalPrint: true,
      screenPrint: false,
      embroidery: false,
      heatTransfer: false,
    },
    // images: {
    //   front: "/PNGs/classicLongSleeve270Front.png",
    //   back: "/PNGs/classicLongSleeve270Back.png",
    // },
    // zoomedImage: "/PNGs/ZoomedImages/zoomed-classic-longsleeve-270.png",
    images: {
      front: "/PNGs/v2/classic-longsleeve-front-updated.png",
      back: "/PNGs/v2/classic-longsleeve-back-updated.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/classic-longsleeve-zoomed-updated.png",
    illustrationImage: "/products/db/v2/classic-longsleeves.png",
    frontIcon: "/product-icons/crewneck-front.svg",
    backIcon: "/product-icons/crewneck-back.svg",
    labelIcon: "/product-icons/crewneck-label.svg",
    heartIcon: "/product-icons/crewneck-heart.svg",
    chestIcon: "/product-icons/crewneck-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Black 6 C", hex: "#1e1e1e", pantone: null, category: "pre-developed", default: true },
        { name: "Volcanic Ash", hex: "#606166", pantone: "19-3912 TXC", category: "pre-developed" },
        { name: "Mulled Grape", hex: "#675a74", pantone: "18-3714 TCX", category: "pre-developed" },
        { name: "Tapioca", hex: "#ddceba", pantone: "12-1403 TCX", category: "pre-developed" },
        { name: "Deep Taupe", hex: "#65534f", pantone: "18-1312 TCX", category: "pre-developed" },
        { name: "Demitasse", hex: "#40332b", pantone: "19-0712 TCX", category: "pre-developed" },
        { name: "High-rise", hex: "#aab1b3", pantone: "15-4101 TCX", category: "pre-developed" },
        { name: "Shadow", hex: "#899187", pantone: "17-6206 TPX", category: "pre-developed" },
        { name: "Bright White", hex: "#f4f9ff", pantone: "11-0601 TCX", category: "pre-developed" },
        { name: "Olive Branch", hex: "#616141", pantone: "18-0527 TCX", category: "pre-developed" },
        { name: "Stratified Sea", hex: "#3b4853", pantone: "19-4112 TCX", category: "pre-developed" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // Crewneck 300
  db300cr: {
    canvaDimensions: { front: [90, 90], back: [90, 90], label: [90, 29.4] }, // cm
    style: productStyleType.Sweatshirt,
    neckSeamPosition: { front: [0, 22], back: [0, 16] },
    get offset() {
      const frontNeckY = this.neckSeamPosition?.front[1] as number;
      const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, addNeckOffset(frontNeckY)],
        back: [0, addNeckOffset(backNeckY)],
      };
    },

    offsetWovenZoomed: [0, 7.5],
    scaleValueZoomed: 1.1,

    offsetWovenNormal: [0, 14],
    scaleValueNormal: 0.9,

    imageDimensions: {
      // change
      width: 977,
      height: 740,
      leftChest: {
        width: 104,
        height: 62,
        x: 540,
        y: 200,
      },
    },

    sizeChartImage: "/size-charts/classic-sweatshirt-updated.png",

    sizeDimensions: {
      length: [
        { size: "XS", dimension: 27.5 },
        { size: "S", dimension: 28.5 },
        { size: "M", dimension: 29.5 },
        { size: "L", dimension: 30.5 },
        { size: "XL", dimension: 31.5 },
        { size: "2XL", dimension: 32.5 },
      ],
      chest: [
        { size: "XS", dimension: 18.5 },
        { size: "S", dimension: 20 },
        { size: "M", dimension: 21.5 },
        { size: "L", dimension: 22.5 },
        { size: "XL", dimension: 24.5 },
        { size: "2XL", dimension: 26.5 },
      ],
      sleeve: [
        { size: "XS", dimension: 7.25 },
        { size: "S", dimension: 7.75 },
        { size: "M", dimension: 8.25 },
        { size: "L", dimension: 8.5 },
        { size: "XL", dimension: 8.75 },
        { size: "2XL", dimension: 9.25 },
      ],
    },

    defaultEmbellishments: {
      digitalPrint: true,
      screenPrint: false,
      embroidery: false,
      heatTransfer: false,
    },

    images: {
      front: "/PNGs/v2/classic-crewneck-front-updated.png",
      back: "/PNGs/v2/classic-crewneck-back-updated.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/classic-crewneck-zoomed-updated-3.png",
    illustrationImage: "/products/db/v2/crewneck-oversized.png",
    frontIcon: "/product-icons/crewneck-front.svg",
    backIcon: "/product-icons/crewneck-back.svg",
    labelIcon: "/product-icons/crewneck-label.svg",
    heartIcon: "/product-icons/crewneck-heart.svg",
    chestIcon: "/product-icons/crewneck-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Black 6 C", hex: "#1e1e1e", pantone: null, category: "pre-developed", default: true },
        { name: "Volcanic Ash", hex: "#606166", pantone: "19-3912 TXC", category: "pre-developed" },
        { name: "Mulled Grape", hex: "#675a74", pantone: "18-3714 TCX", category: "pre-developed" },
        { name: "Tapioca", hex: "#ddceba", pantone: "12-1403 TCX", category: "pre-developed" },
        { name: "Deep Taupe", hex: "#65534f", pantone: "18-1312 TCX", category: "pre-developed" },
        { name: "Demitasse", hex: "#40332b", pantone: "19-0712 TCX", category: "pre-developed" },
        { name: "High-rise", hex: "#aab1b3", pantone: "15-4101 TCX", category: "pre-developed" },
        { name: "Shadow", hex: "#899187", pantone: "17-6206 TPX", category: "pre-developed" },
        { name: "Bright White", hex: "#f4f9ff", pantone: "11-0601 TCX", category: "pre-developed" },
        { name: "Olive Branch", hex: "#616141", pantone: "18-0527 TCX", category: "pre-developed" },
        { name: "Stratified Sea", hex: "#3b4853", pantone: "19-4112 TCX", category: "pre-developed" },
        // { name: "PFD", hex: "#fffaeb", pantone: "PFD", category: "pre-developed" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // Crewneck 600
  db600cr: {
    canvaDimensions: { front: [90, 90], back: [90, 90], label: [90, 29.4] }, // cm
    style: productStyleType.Sweatshirt,
    neckSeamPosition: { front: [0, 22], back: [0, 16] },
    get offset() {
      const frontNeckY = this.neckSeamPosition?.front[1] as number;
      const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, addNeckOffset(frontNeckY)],
        back: [0, addNeckOffset(backNeckY)],
      };
    },

    offsetWovenZoomed: [0, 7.5],
    scaleValueZoomed: 1.1,

    offsetWovenNormal: [0, 14],
    scaleValueNormal: 0.9,

    imageDimensions: {
      // change
      width: 977,
      height: 740,
      leftChest: {
        width: 104,
        height: 62,
        x: 540,
        y: 200,
      },
    },

    sizeChartImage: "/size-charts/classic-sweatshirt-updated.png",

    sizeDimensions: {
      length: [
        { size: "XS", dimension: 27.5 },
        { size: "S", dimension: 28.5 },
        { size: "M", dimension: 29.5 },
        { size: "L", dimension: 30.5 },
        { size: "XL", dimension: 31.5 },
        { size: "2XL", dimension: 32.5 },
      ],
      chest: [
        { size: "XS", dimension: 18.5 },
        { size: "S", dimension: 20 },
        { size: "M", dimension: 21.5 },
        { size: "L", dimension: 22.5 },
        { size: "XL", dimension: 24.5 },
        { size: "2XL", dimension: 26.5 },
      ],
      sleeve: [
        { size: "XS", dimension: 7.25 },
        { size: "S", dimension: 7.75 },
        { size: "M", dimension: 8.25 },
        { size: "L", dimension: 8.5 },
        { size: "XL", dimension: 8.75 },
        { size: "2XL", dimension: 9.25 },
      ],
    },

    defaultEmbellishments: {
      digitalPrint: true,
      screenPrint: false,
      embroidery: false,
      heatTransfer: false,
    },

    images: {
      front: "/PNGs/v2/classic-crewneck-front-updated.png",
      back: "/PNGs/v2/classic-crewneck-back-updated.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/classic-crewneck-zoomed-updated-3.png",
    illustrationImage: "/products/db/v2/crewneck-oversized.png",
    frontIcon: "/product-icons/crewneck-front.svg",
    backIcon: "/product-icons/crewneck-back.svg",
    labelIcon: "/product-icons/crewneck-label.svg",
    heartIcon: "/product-icons/crewneck-heart.svg",
    chestIcon: "/product-icons/crewneck-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Black 6 C", hex: "#1e1e1e", pantone: null, category: "pre-developed" },
        { name: "Volcanic Ash", hex: "#606166", pantone: "19-3912 TXC", category: "pre-developed" },
        { name: "Mulled Grape", hex: "#675a74", pantone: "18-3714 TCX", category: "pre-developed" },
        { name: "Tapioca", hex: "#ddceba", pantone: "12-1403 TCX", category: "pre-developed", default: true },
        { name: "Deep Taupe", hex: "#65534f", pantone: "18-1312 TCX", category: "pre-developed" },
        { name: "Demitasse", hex: "#40332b", pantone: "19-0712 TCX", category: "pre-developed" },
        { name: "High-rise", hex: "#aab1b3", pantone: "15-4101 TCX", category: "pre-developed" },
        { name: "Shadow", hex: "#899187", pantone: "17-6206 TPX", category: "pre-developed" },
        { name: "Bright White", hex: "#f4f9ff", pantone: "11-0601 TCX", category: "pre-developed" },
        { name: "Olive Branch", hex: "#616141", pantone: "18-0527 TCX", category: "pre-developed" },
        { name: "Stratified Sea", hex: "#3b4853", pantone: "19-4112 TCX", category: "pre-developed" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // Hoodie 300
  db300hr: {
    canvaDimensions: { front: [90, 90], back: [90, 90], label: [90, 29.4] }, // cm
    style: productStyleType.Hoodie,
    // copied from classic-hoodie-500 for now
    neckSeamPosition: { front: [0, 20.7], back: [0, 18.5] },
    get offset() {
      const frontNeckY = this.neckSeamPosition?.front[1] as number;
      const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, addNeckOffset(frontNeckY)],
        back: [0, addNeckOffset(backNeckY)],
      };
    },

    offsetWovenZoomed: [-1, 12.5],
    scaleValueZoomed: 1.2,

    offsetWovenNormal: [0, 11.5],
    scaleValueNormal: 0.9,

    imageDimensions: {
      width: 847,
      height: 830,
      leftChest: {
        width: 87,
        height: 52,
        x: 470,
        y: 360,
      },
    },

    sizeChartImage: "/size-charts/boxy-hoodie-updated.png",

    sizeDimensions: {
      length: [
        { size: "XS", dimension: 27.5 },
        { size: "S", dimension: 28.5 },
        { size: "M", dimension: 29.5 },
        { size: "L", dimension: 30.5 },
        { size: "XL", dimension: 31.5 },
        { size: "2XL", dimension: 32.5 },
      ],
      chest: [
        { size: "XS", dimension: 18.5 },
        { size: "S", dimension: 20 },
        { size: "M", dimension: 21.5 },
        { size: "L", dimension: 22.5 },
        { size: "XL", dimension: 24.5 },
        { size: "2XL", dimension: 26.5 },
      ],
      sleeve: [
        { size: "XS", dimension: 7.25 },
        { size: "S", dimension: 7.75 },
        { size: "M", dimension: 8.25 },
        { size: "L", dimension: 8.5 },
        { size: "XL", dimension: 8.75 },
        { size: "2XL", dimension: 9.25 },
      ],
    },

    defaultEmbellishments: {
      digitalPrint: true,
      screenPrint: false,
      embroidery: false,
      heatTransfer: false,
    },

    images: {
      front: "/PNGs/v2/regular-hoodie-front-updated.png",
      back: "/PNGs/v2/regular-hoodie-back-updated.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/regular-hoodie-zoomed-updated.png",
    illustrationImage: "/products/db/v2/hoodie-oversized.png",
    frontIcon: "/product-icons/hoodie-front.svg",
    backIcon: "/product-icons/hoodie-back.svg",
    labelIcon: "/product-icons/hoodie-label.svg",
    heartIcon: "/product-icons/hoodie-heart.svg",
    chestIcon: "/product-icons/hoodie-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Black 6 C", hex: "#1e1e1e", pantone: null, category: "pre-developed" },
        { name: "Volcanic Ash", hex: "#606166", pantone: "19-3912 TXC", category: "pre-developed" },
        { name: "Mulled Grape", hex: "#675a74", pantone: "18-3714 TCX", category: "pre-developed" },
        { name: "Tapioca", hex: "#ddceba", pantone: "12-1403 TCX", category: "pre-developed" },
        { name: "Deep Taupe", hex: "#65534f", pantone: "18-1312 TCX", category: "pre-developed" },
        { name: "Demitasse", hex: "#40332b", pantone: "19-0712 TCX", category: "pre-developed" },
        { name: "High-rise", hex: "#aab1b3", pantone: "15-4101 TCX", category: "pre-developed" },
        { name: "Shadow", hex: "#899187", pantone: "17-6206 TPX", category: "pre-developed" },
        { name: "Bright White", hex: "#f4f9ff", pantone: "11-0601 TCX", category: "pre-developed", default: true },
        { name: "Olive Branch", hex: "#616141", pantone: "18-0527 TCX", category: "pre-developed" },
        { name: "Stratified Sea", hex: "#3b4853", pantone: "19-4112 TCX", category: "pre-developed" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // Hoodie 600 oversize
  db600ho: {
    canvaDimensions: { front: [90, 90], back: [90, 90], label: [90, 29.4] }, // cm
    style: productStyleType.Hoodie,
    // copied from classic-hoodie-500 for now
    neckSeamPosition: { front: [0, 24], back: [0, 17.75] },
    get offset() {
      const frontNeckY = this.neckSeamPosition?.front[1] as number;
      const backNeckY = this.neckSeamPosition?.back[1] as number;
      return {
        front: [0, addNeckOffset(frontNeckY)],
        back: [0, addNeckOffset(backNeckY)],
      };
    },

    offsetWovenZoomed: [-0.65, 14],
    scaleValueZoomed: 1.2,

    offsetWovenNormal: [0, 18.5],
    scaleValueNormal: 0.85,

    imageDimensions: {
      width: 847,
      height: 830,
      leftChest: {
        width: 87,
        height: 52,
        x: 470,
        y: 360,
      },
    },

    sizeChartImage: "/size-charts/boxy-hoodie-updated.png",

    sizeDimensions: {
      length: [
        { size: "XS", dimension: 27.5 },
        { size: "S", dimension: 28.5 },
        { size: "M", dimension: 29.5 },
        { size: "L", dimension: 30.5 },
        { size: "XL", dimension: 31.5 },
        { size: "2XL", dimension: 32.5 },
      ],
      chest: [
        { size: "XS", dimension: 18.5 },
        { size: "S", dimension: 20 },
        { size: "M", dimension: 21.5 },
        { size: "L", dimension: 22.5 },
        { size: "XL", dimension: 24.5 },
        { size: "2XL", dimension: 26.5 },
      ],
      sleeve: [
        { size: "XS", dimension: 7.25 },
        { size: "S", dimension: 7.75 },
        { size: "M", dimension: 8.25 },
        { size: "L", dimension: 8.5 },
        { size: "XL", dimension: 8.75 },
        { size: "2XL", dimension: 9.25 },
      ],
    },

    defaultEmbellishments: {
      digitalPrint: true,
      screenPrint: false,
      embroidery: false,
      heatTransfer: false,
    },
    // images: {
    //   front: "/PNGs/boxyHoodie500Front.png",
    //   back: "/PNGs/boxyHoodie500Back.png",
    // },
    // zoomedImage: "/PNGs/ZoomedImages/zoomed-boxy-hoodie-500.png",
    images: {
      front: "/PNGs/v2/boxy-hoodie-front-updated.png",
      back: "/PNGs/v2/boxy-hoodie-back-updated.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/boxy-hoodie-zoomed-updated.png",
    illustrationImage: "/products/db/v2/hoodie-oversized.png",
    frontIcon: "/product-icons/hoodie-front.svg",
    backIcon: "/product-icons/hoodie-back.svg",
    labelIcon: "/product-icons/hoodie-label.svg",
    heartIcon: "/product-icons/hoodie-heart.svg",
    chestIcon: "/product-icons/hoodie-chest.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0.1,
      S: 0.2,
      M: 0.24,
      L: 0.24,
      XL: 0.12,
      "2XL": 0.1,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Black 6 C", hex: "#1e1e1e", pantone: null, category: "pre-developed" },
        { name: "Volcanic Ash", hex: "#606166", pantone: "19-3912 TXC", category: "pre-developed" },
        { name: "Mulled Grape", hex: "#675a74", pantone: "18-3714 TCX", category: "pre-developed" },
        { name: "Tapioca", hex: "#ddceba", pantone: "12-1403 TCX", category: "pre-developed", default: true },
        { name: "Deep Taupe", hex: "#65534f", pantone: "18-1312 TCX", category: "pre-developed" },
        { name: "Demitasse", hex: "#40332b", pantone: "19-0712 TCX", category: "pre-developed" },
        { name: "High-rise", hex: "#aab1b3", pantone: "15-4101 TCX", category: "pre-developed" },
        { name: "Shadow", hex: "#899187", pantone: "17-6206 TPX", category: "pre-developed" },
        { name: "Bright White", hex: "#f4f9ff", pantone: "11-0601 TCX", category: "pre-developed" },
        { name: "Olive Branch", hex: "#616141", pantone: "18-0527 TCX", category: "pre-developed" },
        { name: "Stratified Sea", hex: "#3b4853", pantone: "19-4112 TCX", category: "pre-developed" },
        { name: "PFD", hex: "#fffaeb", pantone: "PFD", category: "pre-developed" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },

  // Cap
  dbcapr: {
    canvaDimensions: { front: [22, 22], back: [22, 22], left: [31.46, 22], right: [31.46, 22], label: [90, 29.4] }, // cm
    style: productStyleType.Cap,
    // capBasePosition: { front: [0, 1.3], back: [0, 2.5], left: [0, 3], right: [0, 3] },
    capBasePosition: { front: [0, 1.3], back: [0, 2.5], left: [-5.5, 2.5], right: [5.5, 2.5] },
    get offset() {
      const frontBaseX = this.capBasePosition?.front[0] as number;
      const frontBaseY = this.capBasePosition?.front[1] as number;
      const backBaseX = this.capBasePosition?.back[0] as number;
      const backBaseY = this.capBasePosition?.back[1] as number;
      const leftBaseX = this.capBasePosition?.left[0] as number;
      const leftBaseY = this.capBasePosition?.left[1] as number;
      const rightBaseX = this.capBasePosition?.right[0] as number;
      const rightBaseY = this.capBasePosition?.right[1] as number;
      return {
        front: [frontBaseX, frontBaseY + 4.2],
        back: [backBaseX, backBaseY + 4.5],
        left: [leftBaseX, leftBaseY + 5.5],
        right: [rightBaseX - 2, rightBaseY + 5.5], // note when changing: needs to be symmetric across the y-axis
      };
    },

    offsetWovenZoomed: [-11, 6],
    scaleValueZoomed: 2,

    offsetWovenNormal: [2, 9],
    scaleValueNormal: 0, // the woven neck label is not visible in the normal view for caps

    defaultEmbellishments: {
      embroidery: true,
    },
    // images: {
    //   front: "/PNGs/classicDadCapFront.png",
    //   back: "/PNGs/classicDadCapBack.png",
    //   right: "/PNGs/classicDadCapRight.png",
    //   left: "/PNGs/classicDadCapLeft.png",
    // },
    // zoomedImage: "/PNGs/ZoomedImages/zoomed-classic-dad-cap.png",

    sizeChartImage: "/size-charts/classic-dad-cap-updated.png",

    sizeDimensions: {
      circumference: [{ size: "M", dimension: 12 }],
      height: [{ size: "M", dimension: 10 }],
      brimLong: [{ size: "M", dimension: 8 }],
      brimWide: [{ size: "M", dimension: 4 }],
    },

    images: {
      front: "/PNGs/v2/cap-front.png",
      back: "/PNGs/v2/cap-back.png",
      left: "/PNGs/v2/cap-left.png",
      right: "/PNGs/v2/cap-right.png",
    },
    zoomedImage: "/PNGs/v2/zoomed-images/cap-zoomed.png",
    illustrationImage: "/products/db/v2/cap.png",
    frontIcon: "/product-icons/cap-front.svg",
    backIcon: "/product-icons/cap-back.svg",
    rightIcon: "/product-icons/cap-left.svg",
    leftIcon: "/product-icons/cap-right.svg",
    labelIcon: "/product-icons/cap-label.svg",
    frontPrintIcon: "/product-icons/cap-print-front.svg",
    backPrintIcon: "/product-icons/cap-print-back.svg",
    rightPrintIcon: "/product-icons/cap-print-left.svg",
    leftPrintIcon: "/product-icons/cap-print-right.svg",

    sizeWeights: {
      "2XS": 0,
      XS: 0,
      S: 0,
      M: 1,
      L: 0,
      XL: 0,
      "2XL": 0,
      "3XL": 0,
      "4XL": 0,
      "5XL": 0,
    },

    predevelopedColorsConfig: {
      category: "pre-developed",
      colors: [
        { name: "Black 6 C", hex: "#1e1e1e", pantone: null, category: "pre-developed" },
        { name: "Bright White", hex: "#f4f9ff", pantone: "11-0601 TCX", category: "pre-developed", default: true },
        { name: "Stratified Sea", hex: "#3b4853", pantone: "19-4112 TCX", category: "pre-developed" },
      ],
      price_per_quantity: [
        {
          threshold: 50,
          price: 0, // since it's predeveloped
        },
        {
          threshold: 100,
          price: 0,
        },
        {
          threshold: 150,
          price: 0,
        },
        {
          threshold: 300,
          price: 0,
        },
      ],
      lead_time: 0,
    },
  },
};
