export enum productStyleType {
  Custom = "custom",
  Custom4051 = "custom4051",
  Custom4062 = "custom4062",
  Custom4026 = "custom4026",
  Custom5145 = "custom5145",
  Custom5160 = "custom5160",
  Custom5101 = "custom5101",
  Custom5146 = "custom5146",
  Custom5102F = "custom5102F",
  Custom5073 = "custom5073",
  Custom5411 = "custom5411",
  Custom5402 = "custom5402",
  Custom5081 = "custom5081",
  Custom5054F = "custom5054F",
  Custom5056F = "custom5056F",
  Custom5056 = "custom5056",
  Custom5080 = "custom5080",
  Custom5051 = "custom5051",
  Custom5001 = "custom5001",
  Custom5026 = "custom5026",
  Custom5050F = "custom5050F",
  TShirt = "tshirt",
  LongSleeve = "longsleeve",
  Sweatshirt = "sweatshirt",
  Hoodie = "hoodie",
  Cap = "cap",
}
