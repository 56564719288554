import { WovenLabelStitchType } from "@/types/enums/wovenLabelStitchType.enum";

export enum LabelType {
  "50x18" = "50x18",
  "60x20" = "60x20",
  "65x15" = "65x15",
  "45x45" = "45x45",
}

const labelsPricing = [
  {
    dimensions: [50, 18],
    src: "/WovenLabels/woven-label_50_18-updated.png",
    label_images: {
      [WovenLabelStitchType.TwoCorner]: "/PNGs/Labels/label-2-corner-50_18-updated.png",
      [WovenLabelStitchType.FourCorner]: "/PNGs/Labels/label-4-corner-50_18-updated.png",
      [WovenLabelStitchType.TwoSide]: "/PNGs/Labels/label-2-side-50_18-updated.png",
    },
    type: LabelType["50x18"],
    title: "Woven Label 50x18 mm",
    price: 2,
    lead_time: 3,
  },
  {
    dimensions: [60, 20],
    src: "/WovenLabels/woven-label_60_20-updated.png",
    label_images: {
      [WovenLabelStitchType.TwoCorner]: "/PNGs/Labels/label-2-corner-60_20-updated.png",
      [WovenLabelStitchType.FourCorner]: "/PNGs/Labels/label-4-corner-60_20-updated.png",
      [WovenLabelStitchType.TwoSide]: "/PNGs/Labels/label-2-side-60_20-updated.png",
    },
    type: LabelType["60x20"],
    title: "Woven Label 60x20 mm",
    price: 2,
    lead_time: 3,
  },
  {
    dimensions: [65, 15],
    src: "/WovenLabels/woven-label_65_15-updated.png",

    label_images: {
      [WovenLabelStitchType.TwoCorner]: "/PNGs/Labels/label-2-corner-65_15-updated.png",
      [WovenLabelStitchType.FourCorner]: "/PNGs/Labels/label-4-corner-65_15-updated.png",
      [WovenLabelStitchType.TwoSide]: "/PNGs/Labels/label-2-side-65_15-updated.png",
    },
    type: LabelType["65x15"],
    title: "Woven Label 65x15 mm",
    price: 2,
    lead_time: 3,
  },
  {
    src: "/WovenLabels/woven-label_45_45-updated.png",
    dimensions: [45, 45],
    label_images: {
      [WovenLabelStitchType.TwoCorner]: "/PNGs/Labels/label-2-corner-45_45-updated.png",
      [WovenLabelStitchType.FourCorner]: "/PNGs/Labels/label-4-corner-45_45-updated.png",
      [WovenLabelStitchType.TwoSide]: "/PNGs/Labels/label-2-side-45_45-updated.png",
    },
    type: LabelType["45x45"],
    title: "Woven Label 45x45 mm",
    price: 2,
    lead_time: 3,
  },
];

export default labelsPricing;
