import { EmbellishmentType } from "@/types/enums/embellishmentType.enum";

export enum ProductCategory {
  tshirt = "tshirt",
  longsleeve = "longsleeve",
  sweatshirt = "sweatshirt",
  hoodie = "hoodie",
  cap = "cap",
}

const productCategoriesConfig: {
  [key: string]: {
    sizesPerOrientation: {
      [key in string]: { [key: string]: [number, number] };
    };
    embellishmentTypes: string[];
  };
} = {
  custom: {
    sizesPerOrientation: {
      front: {
        XS: [32, 50],
        S: [32, 50],
        M: [32, 50],
        L: [32, 50],
        XL: [32, 50],
        "2XL": [32, 50],
        "3XL": [32, 50],
        "4XL": [32, 50],
        "5XL": [32, 50],
      },
      back: {
        XS: [32, 50],
        S: [32, 50],
        M: [32, 50],
        L: [32, 50],
        XL: [32, 50],
        "2XL": [32, 50],
        "3XL": [32, 50],
        "4XL": [32, 50],
        "5XL": [32, 50],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom4051: {
    sizesPerOrientation: {
      front: {
        XS: [32, 50],
        S: [32, 50],
        M: [32, 50],
        L: [32, 50],
        XL: [32, 50],
        "2XL": [32, 50],
        "3XL": [32, 50],
        "4XL": [32, 50],
        "5XL": [32, 50],
      },
      back: {
        XS: [32, 50],
        S: [32, 50],
        M: [32, 50],
        L: [32, 50],
        XL: [32, 50],
        "2XL": [32, 50],
        "3XL": [32, 50],
        "4XL": [32, 50],
        "5XL": [32, 50],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom4062: {
    sizesPerOrientation: {
      front: {
        XS: [35, 35],
        S: [35, 35],
        M: [35, 35],
        L: [35, 35],
        XL: [35, 35],
        "2XL": [35, 35],
        "3XL": [35, 35],
        "4XL": [35, 35],
        "5XL": [35, 35],
      },
      back: {
        XS: [37, 37],
        S: [37, 37],
        M: [37, 37],
        L: [37, 37],
        XL: [37, 37],
        "2XL": [37, 37],
        "3XL": [37, 37],
        "4XL": [37, 37],
        "5XL": [37, 37],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom4026: {
    sizesPerOrientation: {
      front: {
        XS: [34, 50],
        S: [34, 50],
        M: [34, 50],
        L: [34, 50],
        XL: [34, 50],
        "2XL": [34, 50],
        "3XL": [34, 50],
        "4XL": [34, 50],
        "5XL": [34, 50],
      },
      back: {
        XS: [37, 60],
        S: [37, 60],
        M: [37, 60],
        L: [37, 60],
        XL: [37, 60],
        "2XL": [37, 60],
        "3XL": [37, 60],
        "4XL": [37, 60],
        "5XL": [37, 60],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5145: {
    sizesPerOrientation: {
      front: {
        XS: [40, 50],
        S: [40, 50],
        M: [40, 50],
        L: [40, 50],
        XL: [40, 50],
        "2XL": [40, 50],
        "3XL": [40, 50],
        "4XL": [40, 50],
        "5XL": [40, 50],
      },
      back: {
        XS: [40, 50],
        S: [40, 50],
        M: [40, 50],
        L: [40, 50],
        XL: [40, 50],
        "2XL": [40, 50],
        "3XL": [40, 50],
        "4XL": [40, 50],
        "5XL": [40, 50],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5160: {
    sizesPerOrientation: {
      front: {
        XS: [40, 50],
        S: [40, 50],
        M: [40, 50],
        L: [40, 50],
        XL: [40, 50],
        "2XL": [40, 50],
        "3XL": [40, 50],
        "4XL": [40, 50],
        "5XL": [40, 50],
      },
      back: {
        XS: [40, 50],
        S: [40, 50],
        M: [40, 50],
        L: [40, 50],
        XL: [40, 50],
        "2XL": [40, 50],
        "3XL": [40, 50],
        "4XL": [40, 50],
        "5XL": [40, 50],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5101: {
    sizesPerOrientation: {
      front: {
        XS: [30, 35],
        S: [30, 35],
        M: [30, 35],
        L: [30, 35],
        XL: [30, 35],
        "2XL": [30, 35],
        "3XL": [30, 35],
        "4XL": [30, 35],
        "5XL": [30, 35],
      },
      back: {
        XS: [30, 50],
        S: [30, 50],
        M: [30, 50],
        L: [30, 50],
        XL: [30, 50],
        "2XL": [30, 50],
        "3XL": [30, 50],
        "4XL": [30, 50],
        "5XL": [30, 50],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5146: {
    sizesPerOrientation: {
      front: {
        XS: [42, 32],
        S: [42, 32],
        M: [42, 32],
        L: [42, 32],
        XL: [42, 32],
        "2XL": [42, 32],
        "3XL": [42, 32],
        "4XL": [42, 32],
        "5XL": [42, 32],
      },
      back: {
        XS: [42, 55],
        S: [42, 55],
        M: [42, 55],
        L: [42, 55],
        XL: [42, 55],
        "2XL": [42, 55],
        "3XL": [42, 55],
        "4XL": [42, 55],
        "5XL": [42, 55],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5102F: {
    sizesPerOrientation: {
      front: {
        XS: [40, 35],
        S: [40, 35],
        M: [40, 35],
        L: [40, 35],
        XL: [40, 35],
        "2XL": [40, 35],
        "3XL": [40, 35],
        "4XL": [40, 35],
        "5XL": [40, 35],
      },
      back: {
        XS: [40, 55],
        S: [40, 55],
        M: [40, 55],
        L: [40, 55],
        XL: [40, 55],
        "2XL": [40, 55],
        "3XL": [40, 55],
        "4XL": [40, 55],
        "5XL": [40, 55],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5073: {
    sizesPerOrientation: {
      front: {
        XS: [35, 50],
        S: [35, 50],
        M: [35, 50],
        L: [35, 50],
        XL: [35, 50],
        "2XL": [35, 50],
        "3XL": [35, 50],
        "4XL": [35, 50],
        "5XL": [35, 50],
      },
      back: {
        XS: [35, 55],
        S: [35, 55],
        M: [35, 55],
        L: [35, 55],
        XL: [35, 55],
        "2XL": [35, 55],
        "3XL": [35, 55],
        "4XL": [35, 55],
        "5XL": [35, 55],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5411: {
    sizesPerOrientation: {
      front: {
        XS: [13, 13],
        S: [13, 13],
        M: [13, 13],
        L: [13, 13],
        XL: [13, 13],
        "2XL": [13, 13],
        "3XL": [13, 13],
        "4XL": [13, 13],
        "5XL": [13, 13],
      },
      back: {
        XS: [35, 55],
        S: [35, 55],
        M: [35, 55],
        L: [35, 55],
        XL: [35, 55],
        "2XL": [35, 55],
        "3XL": [35, 55],
        "4XL": [35, 55],
        "5XL": [35, 55],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5402: {
    sizesPerOrientation: {
      front: {
        XS: [15, 15],
        S: [15, 15],
        M: [15, 15],
        L: [15, 15],
        XL: [15, 15],
        "2XL": [15, 15],
        "3XL": [15, 15],
        "4XL": [15, 15],
        "5XL": [15, 15],
      },
      back: {
        XS: [35, 60],
        S: [35, 60],
        M: [35, 60],
        L: [35, 60],
        XL: [35, 60],
        "2XL": [35, 60],
        "3XL": [35, 60],
        "4XL": [35, 60],
        "5XL": [35, 60],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5081: {
    sizesPerOrientation: {
      front: {
        XS: [45, 55],
        S: [45, 55],
        M: [45, 55],
        L: [45, 55],
        XL: [45, 55],
        "2XL": [45, 55],
        "3XL": [45, 55],
        "4XL": [45, 55],
        "5XL": [45, 55],
      },
      back: {
        XS: [45, 55],
        S: [45, 55],
        M: [45, 55],
        L: [45, 55],
        XL: [45, 55],
        "2XL": [45, 55],
        "3XL": [45, 55],
        "4XL": [45, 55],
        "5XL": [45, 55],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5054F: {
    sizesPerOrientation: {
      front: {
        XS: [37, 50],
        S: [37, 50],
        M: [37, 50],
        L: [37, 50],
        XL: [37, 50],
        "2XL": [37, 50],
        "3XL": [37, 50],
        "4XL": [37, 50],
        "5XL": [37, 50],
      },
      back: {
        XS: [37, 60],
        S: [37, 60],
        M: [37, 60],
        L: [37, 60],
        XL: [37, 60],
        "2XL": [37, 60],
        "3XL": [37, 60],
        "4XL": [37, 60],
        "5XL": [37, 60],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5056: {
    sizesPerOrientation: {
      front: {
        XS: [35, 50],
        S: [35, 50],
        M: [35, 50],
        L: [35, 50],
        XL: [35, 50],
        "2XL": [35, 50],
        "3XL": [35, 50],
        "4XL": [35, 50],
        "5XL": [35, 50],
      },
      back: {
        XS: [35, 55],
        S: [35, 55],
        M: [35, 55],
        L: [35, 55],
        XL: [35, 55],
        "2XL": [35, 55],
        "3XL": [35, 55],
        "4XL": [35, 55],
        "5XL": [35, 55],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5080: {
    sizesPerOrientation: {
      front: {
        XS: [50, 60],
        S: [50, 60],
        M: [50, 60],
        L: [50, 60],
        XL: [50, 60],
        "2XL": [50, 60],
        "3XL": [50, 60],
        "4XL": [50, 60],
        "5XL": [50, 60],
      },
      back: {
        XS: [50, 60],
        S: [50, 60],
        M: [50, 60],
        L: [50, 60],
        XL: [50, 60],
        "2XL": [50, 60],
        "3XL": [50, 60],
        "4XL": [50, 60],
        "5XL": [50, 60],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5051: {
    sizesPerOrientation: {
      front: {
        XS: [35, 50],
        S: [35, 50],
        M: [35, 50],
        L: [35, 50],
        XL: [35, 50],
        "2XL": [35, 50],
        "3XL": [35, 50],
        "4XL": [35, 50],
        "5XL": [35, 50],
      },
      back: {
        XS: [35, 55],
        S: [35, 55],
        M: [35, 55],
        L: [35, 55],
        XL: [35, 55],
        "2XL": [35, 55],
        "3XL": [35, 55],
        "4XL": [35, 55],
        "5XL": [35, 55],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5001: {
    sizesPerOrientation: {
      front: {
        XS: [35, 50],
        S: [35, 50],
        M: [35, 50],
        L: [35, 50],
        XL: [35, 50],
        "2XL": [35, 50],
        "3XL": [35, 50],
        "4XL": [35, 50],
        "5XL": [35, 50],
      },
      back: {
        XS: [35, 55],
        S: [35, 55],
        M: [35, 55],
        L: [35, 55],
        XL: [35, 55],
        "2XL": [35, 55],
        "3XL": [35, 55],
        "4XL": [35, 55],
        "5XL": [35, 55],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5026: {
    sizesPerOrientation: {
      front: {
        XS: [35, 50],
        S: [35, 50],
        M: [35, 50],
        L: [35, 50],
        XL: [35, 50],
        "2XL": [35, 50],
        "3XL": [35, 50],
        "4XL": [35, 50],
        "5XL": [35, 50],
      },
      back: {
        XS: [35, 55],
        S: [35, 55],
        M: [35, 55],
        L: [35, 55],
        XL: [35, 55],
        "2XL": [35, 55],
        "3XL": [35, 55],
        "4XL": [35, 55],
        "5XL": [35, 55],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  custom5050F: {
    sizesPerOrientation: {
      front: {
        XS: [35, 50],
        S: [35, 50],
        M: [35, 50],
        L: [35, 50],
        XL: [35, 50],
        "2XL": [35, 50],
        "3XL": [35, 50],
        "4XL": [35, 50],
        "5XL": [35, 50],
      },
      back: {
        XS: [35, 55],
        S: [35, 55],
        M: [35, 55],
        L: [35, 55],
        XL: [35, 55],
        "2XL": [35, 55],
        "3XL": [35, 55],
        "4XL": [35, 55],
        "5XL": [35, 55],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  tshirt: {
    sizesPerOrientation: {
      front: {
        XS: [31, 43],
        S: [32.375, 43.875],
        M: [33.75, 44.75],
        L: [35.125, 45.625],
        XL: [36.5, 46.5],
        "2XL": [37.875, 47.375],
        "3XL": [39.25, 48.25],
        "4XL": [40.625, 49.125],
        "5XL": [42, 50],
      },
      back: {
        XS: [31, 43],
        S: [32.375, 43.875],
        M: [33.75, 44.75],
        L: [35.125, 45.625],
        XL: [36.5, 46.5],
        "2XL": [37.875, 47.375],
        "3XL": [39.25, 48.25],
        "4XL": [40.625, 49.125],
        "5XL": [42, 50],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  longsleeve: {
    sizesPerOrientation: {
      front: {
        XS: [31, 43],
        S: [32.375, 43.875],
        M: [33.75, 44.75],
        L: [35.125, 45.625],
        XL: [36.5, 46.5],
        "2XL": [37.875, 47.375],
        "3XL": [39.25, 48.25],
        "4XL": [40.625, 49.125],
        "5XL": [42, 50],
      },
      back: {
        XS: [31, 43],
        S: [32.375, 43.875],
        M: [33.75, 44.75],
        L: [35.125, 45.625],
        XL: [36.5, 46.5],
        "2XL": [37.875, 47.375],
        "3XL": [39.25, 48.25],
        "4XL": [40.625, 49.125],
        "5XL": [42, 50],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  sweatshirt: {
    sizesPerOrientation: {
      front: {
        XS: [33, 35],
        S: [33.875, 35.375],
        M: [34.75, 35.75],
        L: [35.625, 36.125],
        XL: [36.5, 36.5],
        "2XL": [37.375, 36.875],
        "3XL": [38.25, 37.25],
        "4XL": [39.125, 37.625],
        "5XL": [40, 38],
      },
      back: {
        XS: [33, 39],
        S: [35.625, 39.375],
        M: [36.25, 39.75],
        L: [36.875, 40.125],
        XL: [37.5, 40.5],
        "2XL": [38.125, 40.875],
        "3XL": [38.75, 41.25],
        "4XL": [39.375, 41.625],
        "5XL": [40, 42],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  hoodie: {
    sizesPerOrientation: {
      front: {
        XS: [36, 18],
        S: [36.5, 18.375],
        M: [37, 18.75],
        L: [37.5, 19.125],
        XL: [38, 19.5],
        "2XL": [38.5, 19.875],
        "3XL": [39, 20.25],
        "4XL": [39.5, 20.625],
        "5XL": [40, 21],
      },
      back: {
        XS: [36, 41],
        S: [36.5, 42.375],
        M: [37, 43.75],
        L: [37.5, 45.125],
        XL: [38, 46.5],
        "2XL": [38.5, 47.875],
        "3XL": [39, 49.25],
        "4XL": [39.5, 50.625],
        "5XL": [40, 52],
      },
    },
    embellishmentTypes: Object.values(EmbellishmentType),
  },
  cap: {
    sizesPerOrientation: {
      front: {
        XS: [12, 5.5],
      },
      back: {
        XS: [10, 3.5],
      },
      left: {
        XS: [6.5, 5],
      },
      right: {
        XS: [6.5, 5],
      },
    },
    embellishmentTypes: [EmbellishmentType.Embroidery],
  },
};

export default productCategoriesConfig;
