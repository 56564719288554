import { IColor, ProductColorMode } from "@/app/admin-store/(authenticated)/products/create/newProductConstants";
import { CanvaArtwork, CanvaData, CanvaImageItemMetadata } from "@/components/canva/ICanva";
import labelsPricing, { LabelType } from "@/config/labelsPricingConfig";
import { createClient } from "@/lib/supabase/client";
import { SupabaseUtils } from "@/lib/supabase/supabaseUtils";
import { calcLabelArtworkSizePosition, calcLabelSizePosition, getRectSizePosition } from "@/lib/utils/artworkUtils";
import { getCanvaData, mergeCanvaArtworkDimenstions } from "@/lib/utils/canvaUtils";
import { propertyIn } from "@/lib/utils/fpUtils";
import { getLabelBgImage } from "@/lib/utils/labelUtils";
import { Tables } from "@/types";
import { OrientationType } from "@/types/enums/orientationType.enum";
import { productStyleType } from "@/types/enums/productStyleType.enum";
import { WovenLabelPositionType } from "@/types/enums/wovenLabelPositionType.enum";
import { WovenLabelStitchType } from "@/types/enums/wovenLabelStitchType.enum";
import { Size } from "@/types/supabase-custom/Customization";
import {
  assign,
  cloneDeep,
  concat,
  divide,
  eq,
  filter,
  find,
  findIndex,
  flow,
  gt,
  map,
  matchesProperty,
  merge,
  negate,
  property,
  set,
  some,
} from "lodash";
import fp from "lodash/fp";
import { v4 as uuidv4 } from "uuid";
// import { productsConfig } from "@/config/productsConfig";

export enum ArtworkType {
  body_artwork = "body_artwork",
  body_artwork_placeholder = "body_artwork_placeholder",
  label_background_artwork = "label_background_artwork",
  label_artwork = "label_artwork",
}

export type CanvaOrientations = {
  [key in OrientationType]: CanvaData & { minRequiredSize: Size };
};

export enum CanvaOrientationsActionType {
  SET = "SET",
  SET_ALL_ORIENTATIONS = "SET_ALL_ORIENTATIONS",
  UPDATE_ALL_ORIENTATIONS_WITH_COLOR_CONFIG = "UPDATE_ALL_ORIENTATIONS_WITH_COLOR_CONFIG",
  UPDATE_PROPERTY = "UPDATE_PROPERTY",
  UPDATE_COLOR = "UPDATE_COLOR",
  TOGGLE_GRID = "TOGGLE_GRID",
  ADD_PLACEHOLDER_ARTWORK = "ADD_PLACEHOLDER_ARTWORK",
  ADD_ARTWORK = "ADD_ARTWORK",
  REMOVE_ARTWORK = "REMOVE_ARTWORK",
  UPDATE_ARTWORK = "UPDATE_ARTWORK",
  CENTER_ARTWORK = "CENTER_ARTWORK",
  HEART_ARTWORK = "HEART_ARTWORK",
  FLIP_ARTWORK_VERTICALLY = "FLIP_ARTWORK_VERTICALLY",
  FLIP_ARTWORK_HORIZONTALLY = "FLIP_ARTWORK_HORIZONTALLY",
  CLONE_ARTWORK = "CLONE_ARTWORK",
  ADD_LABEL = "ADD_LABEL",
  UPDATE_GRID_SIZE = "UPDATE_GRID_SIZE",
  ADD_DEFAULT_LABEL_BACKGROUND = "ADD_DEFAULT_LABEL_BACKGROUND",
  REMOVE_LABEL = "REMOVE_LABEL",
  UPDATE_LABEL_BACKGROUND = "UPDATE_LABEL_BACKGROUND",
  ADD_LABEL_ARTWORK = "ADD_LABEL_ARTWORK",
}

export type CanvaOrientationsAction =
  | {
      type: CanvaOrientationsActionType.SET;
      payload: {
        orientation: OrientationType;
        data: CanvaData;
      };
    }
  | {
      type: CanvaOrientationsActionType.SET_ALL_ORIENTATIONS;
      payload: {
        data: CanvaOrientations;
      };
    }
  | {
      type: CanvaOrientationsActionType.UPDATE_ALL_ORIENTATIONS_WITH_COLOR_CONFIG;
      payload: { colorConfig: IColor; tenantUUID: string; product: Tables<"products"> };
    }
  | {
      type: CanvaOrientationsActionType.UPDATE_COLOR;
      payload: {
        value: string;
      };
    }
  | {
      type: CanvaOrientationsActionType.TOGGLE_GRID;
    }
  | {
      type: CanvaOrientationsActionType.UPDATE_PROPERTY;
      payload: {
        orientation: OrientationType;
        key: string;
        value: any;
      };
    }
  | {
      type: CanvaOrientationsActionType.ADD_PLACEHOLDER_ARTWORK;
      payload: {
        orientation: OrientationType;
        productHandle: string;
        defaultEmbellishmentType: string;
      };
    }
  | {
      type: CanvaOrientationsActionType.ADD_ARTWORK;
      payload: {
        orientation: OrientationType;
        artwork: CanvaArtwork;
      };
    }
  | {
      type: CanvaOrientationsActionType.REMOVE_ARTWORK;
      payload: {
        orientation: OrientationType;
        uuid: string;
      };
    }
  | {
      type: CanvaOrientationsActionType.UPDATE_ARTWORK;
      payload: {
        orientation: OrientationType;
        uuid: string;
        key: string;
        value: any;
      };
    }
  | {
      type: CanvaOrientationsActionType.CENTER_ARTWORK;
      payload: {
        orientation: OrientationType;
        uuid: string;
      };
    }
  | {
      type: CanvaOrientationsActionType.HEART_ARTWORK;
      payload: {
        orientation: OrientationType;
        colorConfig: IColor;
        uuid: string;
      };
    }
  | {
      type: CanvaOrientationsActionType.FLIP_ARTWORK_VERTICALLY;
      payload: {
        orientation: OrientationType;
        uuid: string;
      };
    }
  | {
      type: CanvaOrientationsActionType.FLIP_ARTWORK_HORIZONTALLY;
      payload: {
        orientation: OrientationType;
        uuid: string;
      };
    }
  | {
      type: CanvaOrientationsActionType.CLONE_ARTWORK;
      payload: {
        orientation: OrientationType;
        uuid: string;
      };
    }
  | {
      type: CanvaOrientationsActionType.ADD_LABEL;
      payload: {
        orientation: OrientationType;
        uuid: string;
      };
    }
  | {
      type: CanvaOrientationsActionType.UPDATE_GRID_SIZE;
      payload: {
        productHandle: string;
        size: Size;
      };
    }
  | {
      type: CanvaOrientationsActionType.ADD_DEFAULT_LABEL_BACKGROUND;
      payload: { colorConfig: IColor };
    }
  | {
      type: CanvaOrientationsActionType.REMOVE_LABEL;
    }
  | {
      type: CanvaOrientationsActionType.UPDATE_LABEL_BACKGROUND;
      payload: { colorConfig: IColor; path: string; value: any };
    }
  | {
      type: CanvaOrientationsActionType.ADD_LABEL_ARTWORK;
      payload: { artwork: CanvaArtwork; colorConfig: IColor };
    };

const supabaseUtils = new SupabaseUtils(createClient());

export function canvaOrientationsReducer(
  canvaOrientations: CanvaOrientations,
  actions: CanvaOrientationsAction
): CanvaOrientations {
  canvaOrientations = cloneDeep(canvaOrientations);

  switch (actions.type) {
    case CanvaOrientationsActionType.SET: {
      return set(
        canvaOrientations,
        actions.payload.orientation,
        merge(canvaOrientations[actions.payload.orientation], actions.payload.data)
      );
    }

    case CanvaOrientationsActionType.SET_ALL_ORIENTATIONS: {
      return actions.payload.data;
    }

    case CanvaOrientationsActionType.UPDATE_ALL_ORIENTATIONS_WITH_COLOR_CONFIG: {
      const { tenantUUID, colorConfig, product } = actions.payload;

      for (const orientation in canvaOrientations) {
        const newCanvaData = getCanvaData({
          tenantUUID,
          orientation: orientation as OrientationType,
          productUUID: product.uuid,
          colorConfig,
          colorMode: product.color_mode as ProductColorMode,
        });

        assign(canvaOrientations[orientation as OrientationType], {
          background: newCanvaData.background,
          editableArea: newCanvaData.editableArea,
          dimensions: newCanvaData.dimensions,
        });

        // update label  position
        const labelBgArtwork = find(
          canvaOrientations[orientation as OrientationType].artworks,
          matchesProperty("metadata.type", ArtworkType.label_background_artwork)
        ) as CanvaArtwork | undefined;
        const labelArtwork = find(
          canvaOrientations[orientation as OrientationType].artworks,
          matchesProperty("metadata.type", ArtworkType.label_artwork)
        );
        if (labelBgArtwork && labelArtwork) {
          labelBgArtwork.location = calcLabelSizePosition(colorConfig, labelBgArtwork, orientation as OrientationType);
          labelArtwork.location = calcLabelArtworkSizePosition({
            colorConfig,
            labelBgArtwork,
            labelArtwork,
            orientation: orientation as OrientationType,
          });
        }
      }

      return canvaOrientations;
    }

    case CanvaOrientationsActionType.UPDATE_PROPERTY: {
      return set(canvaOrientations, `${actions.payload.orientation}.${actions.payload.key}`, actions.payload.value);
    }

    case CanvaOrientationsActionType.UPDATE_COLOR: {
      for (const orientation in canvaOrientations) {
        set(canvaOrientations, `${orientation}.background.filterHex`, actions.payload.value);
        set(canvaOrientations, `${orientation}.background.hex`, actions.payload.value);
      }

      return canvaOrientations;
    }

    case CanvaOrientationsActionType.TOGGLE_GRID: {
      const isVisible = Boolean(canvaOrientations.front.editableArea?.visible);
      for (const orientation in canvaOrientations) {
        set(canvaOrientations, `${orientation}.editableArea.visible`, !isVisible);
      }

      return canvaOrientations;
    }

    case CanvaOrientationsActionType.ADD_PLACEHOLDER_ARTWORK: {
      const orientationArtworks = canvaOrientations[actions.payload.orientation].artworks;
      if (orientationArtworks && gt(orientationArtworks.length, 0)) {
        return canvaOrientations;
      }

      const placeholderArtwork: CanvaArtwork = {
        type: "image",
        uuid: uuidv4(),
        name: "placeholder",
        draggable: true,
        resizable: true,
        location: { x: 0, y: 0 },
        dimensions: { width: 0, height: 0 },
        rotation: 0,
        metadata: {
          type: ArtworkType.body_artwork_placeholder,
          imageUrl: "",
          embellishmentType: actions.payload.defaultEmbellishmentType,
          colorSelectionTechnique: "standard",
        },
      };

      return set(canvaOrientations, `${actions.payload.orientation}.artworks`, [placeholderArtwork]);
    }

    case CanvaOrientationsActionType.ADD_ARTWORK: {
      // merge with placeholder artwork (if exists)
      const placeholderArtwork = find(
        canvaOrientations[actions.payload.orientation].artworks,
        matchesProperty("metadata.type", ArtworkType.body_artwork_placeholder)
      );
      if (placeholderArtwork) {
        set(
          actions.payload.artwork,
          `metadata.embellishmentType`,
          (placeholderArtwork.metadata as any).embellishmentType
        );
        set(
          actions.payload.artwork,
          `metadata.colorSelectionTechnique`,
          (placeholderArtwork.metadata as any).colorSelectionTechnique
        );
        return set(canvaOrientations, `${actions.payload.orientation}.artworks`, [actions.payload.artwork]);
      }

      const newArtworks = concat(canvaOrientations[actions.payload.orientation].artworks, actions.payload.artwork);
      return set(canvaOrientations, `${actions.payload.orientation}.artworks`, newArtworks);
    }

    case CanvaOrientationsActionType.REMOVE_ARTWORK: {
      const newArtworks = filter(
        canvaOrientations[actions.payload.orientation].artworks,
        negate(matchesProperty("uuid", actions.payload.uuid))
      );

      return set(canvaOrientations, `${actions.payload.orientation}.artworks`, newArtworks);
    }

    case CanvaOrientationsActionType.UPDATE_ARTWORK: {
      const artworkIndex = findIndex(
        canvaOrientations[actions.payload.orientation].artworks,
        matchesProperty("uuid", actions.payload.uuid)
      );

      return set(
        canvaOrientations,
        `${actions.payload.orientation}.artworks[${artworkIndex}].${actions.payload.key}`,
        actions.payload.value
      );
    }

    case CanvaOrientationsActionType.CENTER_ARTWORK: {
      const { orientation, uuid } = actions.payload;
      const canvaData = canvaOrientations[orientation];
      const artworkIndex = findIndex(canvaData.artworks, matchesProperty("uuid", uuid));
      const artwork = canvaData.artworks?.[artworkIndex];
      if (!artwork) return canvaOrientations;

      // dimensions
      const ratio = divide(artwork.dimensions?.width as number, artwork.dimensions?.height as number);
      let width = Number(canvaData.editableArea?.width) * 0.8;
      let height = width / ratio;
      const heightExceedsRectangle = height > Number(canvaData.editableArea?.height) * 0.8;
      if (heightExceedsRectangle) {
        height = Number(canvaData.editableArea?.height) * 0.8;
        width = height * ratio;
      }

      // location
      const flippedY = (artwork.metadata as CanvaImageItemMetadata).isFlippedY ? height : 0;
      const flippedX = (artwork.metadata as CanvaImageItemMetadata).isFlippedX ? width : 0;
      const x =
        (canvaData.editableArea?.x as number) + ((canvaData.editableArea?.width as number) / 2 - width / 2) + flippedX;
      // const x = colorConfig.orientationCardsValue[orientation].productSetterData.
      const y =
        (canvaData.editableArea?.y as number) +
        ((canvaData.editableArea?.height as number) / 2 - height / 2) +
        flippedY;

      return set(
        canvaOrientations,
        `${orientation}.artworks[${artworkIndex}]`,
        assign(artwork, {
          dimensions: { width, height },
          location: { x, y },
        })
      );
    }

    case CanvaOrientationsActionType.HEART_ARTWORK: {
      const { orientation, uuid, colorConfig } = actions.payload;
      const canvaData = canvaOrientations[orientation];
      const artworkIndex = findIndex(canvaData.artworks, matchesProperty("uuid", uuid));
      const artwork = canvaData.artworks?.[artworkIndex];
      if (!artwork) return canvaOrientations;

      const ratio = divide(artwork.dimensions?.width as number, artwork.dimensions?.height as number);
      const {
        heartX,
        heartY,
        heartWidth: heartWidthCm,
        _imagePxPerCm,
      } = colorConfig.orientationCardsValue[orientation].productSetterData;
      const [heartXCm, heartYCm] = map([heartX, heartY] as number[], (n) => n / (_imagePxPerCm || 1));
      const heartHeightCm = Number(heartWidthCm) / ratio;
      const flippedY = (artwork.metadata as CanvaImageItemMetadata).isFlippedY ? Number(heartHeightCm) : 0;
      const flippedX = (artwork.metadata as CanvaImageItemMetadata).isFlippedX ? Number(heartWidthCm) : 0;

      return set(
        canvaOrientations,
        `${orientation}.artworks[${artworkIndex}]`,
        assign(artwork, {
          dimensions: { width: heartWidthCm, height: heartHeightCm },
          location: { x: heartXCm + flippedX, y: heartYCm + flippedY },
        })
      );
    }

    case CanvaOrientationsActionType.FLIP_ARTWORK_VERTICALLY: {
      const { orientation, uuid } = actions.payload;
      const canvaData = canvaOrientations[orientation];
      const artworkIndex = findIndex(canvaData.artworks, matchesProperty("uuid", uuid));
      const artwork = canvaData.artworks?.[artworkIndex];
      if (!artwork) return canvaOrientations;

      const isFlippedY = (artwork.metadata as CanvaImageItemMetadata).isFlippedY;
      const y =
        Number(artwork.location?.y) +
        (isFlippedY ? -Number(artwork.dimensions?.height) : Number(artwork.dimensions?.height));

      return set(
        canvaOrientations,
        `${orientation}.artworks[${artworkIndex}]`,
        assign(artwork, {
          metadata: assign(artwork.metadata, { isFlippedY: !isFlippedY }),
          location: assign(artwork.location, { y }),
        })
      );
    }

    case CanvaOrientationsActionType.FLIP_ARTWORK_HORIZONTALLY: {
      const { orientation, uuid } = actions.payload;
      const canvaData = canvaOrientations[orientation];
      const artworkIndex = findIndex(canvaData.artworks, matchesProperty("uuid", uuid));
      const artwork = canvaData.artworks?.[artworkIndex];
      if (!artwork) return canvaOrientations;

      const isFlippedX = (artwork.metadata as CanvaImageItemMetadata).isFlippedX;
      const x =
        Number(artwork.location?.x) +
        (isFlippedX ? -Number(artwork.dimensions?.width) : Number(artwork.dimensions?.width));

      return set(
        canvaOrientations,
        `${orientation}.artworks[${artworkIndex}]`,
        assign(artwork, {
          metadata: assign(artwork.metadata, { isFlippedX: !isFlippedX }),
          location: assign(artwork.location, { x }),
        })
      );
    }

    case CanvaOrientationsActionType.CLONE_ARTWORK: {
      const { orientation, uuid } = actions.payload;
      const artwork = find(canvaOrientations[orientation].artworks, matchesProperty("uuid", uuid));
      if (!artwork) return canvaOrientations;

      const newArtworks = concat(canvaOrientations[orientation].artworks, {
        ...cloneDeep(artwork),
        uuid: uuidv4(),
        location: { x: (artwork.location?.x as number) + 1, y: (artwork.location?.y as number) + 1 },
      });

      return set(canvaOrientations, `${orientation}.artworks`, newArtworks);
    }

    case CanvaOrientationsActionType.ADD_LABEL: {
      const { orientation, uuid } = actions.payload;
      const artwork = find(canvaOrientations[orientation].artworks, matchesProperty("uuid", uuid));
      if (!artwork) return canvaOrientations;

      const newArtworks = concat(canvaOrientations[orientation].artworks, {
        ...artwork,
        uuid: uuidv4(),
        location: { x: (artwork.location?.x as number) + 50, y: (artwork.location?.y as number) + 50 },
      });

      return set(canvaOrientations, `${orientation}.artworks`, newArtworks);
    }

    case CanvaOrientationsActionType.UPDATE_GRID_SIZE: {
      for (const orientation in canvaOrientations) {
        set(canvaOrientations, `${orientation}.minRequiredSize`, actions.payload.size);
        set(canvaOrientations, `${orientation}.editableArea`, {
          ...getRectSizePosition({
            productHandle: actions.payload.productHandle,
            orientation: orientation as OrientationType,
            minGridSize: actions.payload.size,
          }),
          visible: true,
        });
      }

      return canvaOrientations;
    }

    case CanvaOrientationsActionType.REMOVE_LABEL: {
      for (const orientation of [OrientationType.Front, OrientationType.Label]) {
        const updatedArtworks = filter(
          canvaOrientations[orientation].artworks,
          negate(propertyIn("metadata.type", [ArtworkType.label_artwork, ArtworkType.label_background_artwork]))
        );

        set(canvaOrientations, `${orientation}.artworks`, updatedArtworks);
      }

      return canvaOrientations;
    }

    case CanvaOrientationsActionType.ADD_DEFAULT_LABEL_BACKGROUND: {
      function getLabelBgArtwork() {
        const defaultLabelType = LabelType["50x18"];
        // const defaultStitch = eq(productCategory, productStyleType.Cap)
        //   ? WovenLabelStitchType.TwoSide
        //   : WovenLabelStitchType.TwoCorner;
        const defaultStitch = WovenLabelStitchType.TwoCorner;

        const result: CanvaArtwork = {
          uuid: uuidv4(),
          type: "image",
          location: { x: 0, y: 0 },
          dimensions: { width: 0, height: 0 },
          rotation: 0,
          metadata: {
            type: ArtworkType.label_background_artwork,
            imageUrl: flow(
              fp.find(matchesProperty("type", defaultLabelType)),
              property<(typeof labelsPricing)[0]["label_images"], string>(`label_images.${defaultStitch}`)
            )(labelsPricing),
            labelType: defaultLabelType,
            labelSize: [50, 18],
            labelPosition: WovenLabelPositionType.BelowNeckTape,
            labelStitch: defaultStitch,
          },
          resizable: false,
          draggable: false,
        };

        return result;
      }

      // Add label bg artwork to both front / label views
      for (const orientation of [OrientationType.Front, OrientationType.Label]) {
        const hasLabelBg = some(
          canvaOrientations[orientation].artworks,
          matchesProperty("metadata.type", ArtworkType.label_background_artwork)
        );

        if (!hasLabelBg) {
          const newArtwork = mergeCanvaArtworkDimenstions(
            getLabelBgArtwork(),
            calcLabelSizePosition(actions.payload.colorConfig, getLabelBgArtwork(), orientation)
          );
          const artworks = concat(canvaOrientations[orientation].artworks, newArtwork);
          set(canvaOrientations, `${orientation}.artworks`, artworks);
        }
      }

      return canvaOrientations;
    }

    case CanvaOrientationsActionType.UPDATE_LABEL_BACKGROUND: {
      const { colorConfig, path, value } = actions.payload;

      for (const orientation of [OrientationType.Front, OrientationType.Label]) {
        // Update payload property
        const labelBgArtworkIndex = findIndex(
          canvaOrientations[orientation].artworks,
          matchesProperty("metadata.type", ArtworkType.label_background_artwork)
        );
        if (labelBgArtworkIndex == -1) break;
        set(canvaOrientations, `${orientation}.artworks[${labelBgArtworkIndex}].${path}`, value);

        // 2. update location / dimensions / image of label background artwork
        const labelBgArtwork = canvaOrientations[orientation].artworks?.[labelBgArtworkIndex] as CanvaArtwork;
        const updatedLabelBgArtwork = mergeCanvaArtworkDimenstions(
          labelBgArtwork,
          calcLabelSizePosition(colorConfig, labelBgArtwork, orientation)
        );
        (updatedLabelBgArtwork.metadata as CanvaImageItemMetadata).imageUrl = getLabelBgImage(updatedLabelBgArtwork);
        set(canvaOrientations, `${orientation}.artworks[${labelBgArtworkIndex}]`, updatedLabelBgArtwork);

        // 3. update location / dimensions of label artwork
        const labelArtworkIndex = findIndex(
          canvaOrientations[orientation].artworks,
          matchesProperty("metadata.type", ArtworkType.label_artwork)
        );
        if (labelArtworkIndex == -1) continue;
        const labelArtwork = canvaOrientations[orientation].artworks?.[labelArtworkIndex] as CanvaArtwork;
        const updatedLabelArtwork = mergeCanvaArtworkDimenstions(
          labelArtwork,
          calcLabelArtworkSizePosition({ colorConfig, orientation, labelArtwork, labelBgArtwork })
        );
        set(canvaOrientations, `${orientation}.artworks[${labelArtworkIndex}]`, updatedLabelArtwork);
      }

      return canvaOrientations;
    }

    case CanvaOrientationsActionType.ADD_LABEL_ARTWORK: {
      // Add label bg artwork to both front / label views
      for (const orientation of [OrientationType.Front, OrientationType.Label]) {
        const labelBgArtwork = find(
          canvaOrientations[orientation].artworks,
          matchesProperty("metadata.type", ArtworkType.label_background_artwork)
        );
        const labelArtwork = find(
          canvaOrientations[orientation].artworks,
          matchesProperty("metadata.type", ArtworkType.label_artwork)
        );

        if (labelArtwork || !labelBgArtwork) break;

        // Add size position to artwork
        const updatedLabelArtwork = mergeCanvaArtworkDimenstions(
          actions.payload.artwork,
          calcLabelArtworkSizePosition({
            orientation,
            labelBgArtwork,
            labelArtwork: actions.payload.artwork,
            colorConfig: actions.payload.colorConfig,
          })
        );

        const artworks = concat(canvaOrientations[orientation].artworks, updatedLabelArtwork);
        set(canvaOrientations, `${orientation}.artworks`, artworks);
      }

      return canvaOrientations;
    }

    default: {
      return canvaOrientations;
    }
  }
}
