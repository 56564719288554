import { CanvaArtwork, CanvaImageItemMetadata } from "@/components/canva/ICanva";
import labelsPricing, { LabelType } from "@/config/labelsPricingConfig";
import { WovenLabelPositionType } from "@/types/enums/wovenLabelPositionType.enum";
import { WovenLabelStitchType } from "@/types/enums/wovenLabelStitchType.enum";
import { LabelCustomization } from "@/types/supabase-custom/Customization";
import { flow, matchesProperty, property } from "lodash";
import { find } from "lodash/fp";
import { v4 as uuidv4 } from "uuid";

export function getInitialLabel(): LabelCustomization {
  return {
    type: LabelType["50x18"],
    position: WovenLabelPositionType.BelowNeckTape,
    size: [50, 18],
    stitch: WovenLabelStitchType.TwoCorner,
    name: "Label",
    uuid: uuidv4(),
  };
}

export function getLabelBgImage(artwork: CanvaArtwork) {
  const { labelType, labelStitch } = artwork.metadata as CanvaImageItemMetadata;

  const result = flow(
    find(matchesProperty("type", labelType)),
    property<(typeof labelsPricing)[0]["label_images"], string>(`label_images.${labelStitch}`)
  )(labelsPricing);

  return result;
}
