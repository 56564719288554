import { CanvaArtwork, CanvaData, CanvaImageItemMetadata } from "@/components/canva/ICanva";
import consts from "@/config/consts";
import productCategoriesConfig from "@/config/productCategoriesConfig";
import { OrientationType } from "@/types/enums/orientationType.enum";
import { WovenLabelPositionType } from "@/types/enums/wovenLabelPositionType.enum";
import { Size } from "@/types/supabase-custom/Customization";
import {
  eq,
  gt,
  map,
  subtract,
  divide,
  multiply,
  round,
  cloneDeep,
  property,
  flow,
  values,
  matchesProperty,
} from "lodash";
import fp from "lodash/fp";
import { WovenLabelStitchType } from "@/types/enums/wovenLabelStitchType.enum";
import { LabelType } from "@/config/labelsPricingConfig";
import {
  ArtworkType,
  CanvaOrientations,
} from "@/app/studio/(public)/products/[productHandle]/components/reducers/canvaOrientationsReducer";
import { EmbellishmentType } from "@/types/enums/embellishmentType.enum";
import { PDFDocument } from "pdf-lib";
import { err, ok, Result } from "neverthrow";
import { ErrorObject } from "@/hooks/useNeverthrowAsync";
import { IColor } from "@/app/admin-store/(authenticated)/products/create/newProductConstants";
import { productsConfig } from "@/config/productsConfig";

export function px2cm(px: number) {
  return px / consts.PIXELS_PER_CM;
}

export function mm2cm(mm: number) {
  return divide(mm, 10);
}

export function getRectSizePosition({
  productHandle,
  orientation,
  minGridSize = Size.XS,
}: {
  productHandle: string;
  orientation: OrientationType;
  minGridSize?: Size;
}) {
  const productConfig = productsConfig[productHandle];
  const [canvaWidthCm] = productConfig.canvaDimensions?.[orientation] || [0, 0];
  const [rectWidthCm, rectHeightCm] = productCategoriesConfig[productConfig.style].sizesPerOrientation?.[orientation]?.[
    minGridSize
  ] || [0, 0];

  const [offsetXCm, offsetYCm] = productConfig?.offset?.[orientation] || [0, 0];

  const width = rectWidthCm;
  const height = rectHeightCm;
  const x = divide(subtract(canvaWidthCm, width), 2) + offsetXCm;
  const y = offsetYCm;

  return { width, height, x, y };
}

export function initArtworkSizePosition({
  editableArea,
  img,
}: {
  editableArea: CanvaData["editableArea"];
  img: HTMLImageElement;
}) {
  const {
    width: rectWidth,
    height: rectHeight,
    x: rectX,
    y: rectY,
  } = editableArea || { width: 0, height: 0, x: 0, y: 0 };
  const ratio = img.naturalWidth / img.naturalHeight;

  let width = rectWidth * consts.INITIAL_ARTWORK_PERCENTAGE;
  let height = width / ratio;
  const heightExceedsRectangle = height > rectHeight * 0.75;
  if (heightExceedsRectangle) {
    height = rectHeight * 0.75;
    width = height * ratio;
  }

  const x = rectX + (rectWidth / 2 - width / 2);
  const y = rectY + 1;

  return { width, height, x, y };
}

export function getFromCenter(canvaData: CanvaData, artwork: CanvaArtwork) {
  const { width: canvaWidthCm } = canvaData.dimensions || { width: 0, height: 0 };

  const canvaCenterX = canvaWidthCm / 2;
  const artworkCenterX = artwork.location.x + divide(artwork.dimensions.width, 2);
  const fromCenter = round(artworkCenterX, 1) - round(canvaCenterX, 1);

  return fromCenter;
}

export function getFromNeckSeam(canvaData: CanvaData, artwork: CanvaArtwork) {
  const { y: editableAreaYCm } = canvaData.editableArea || { y: 0 };

  const fromNeckSeam = consts.NECK_SEAM_Y_CM + (artwork.location.y - editableAreaYCm);

  return fromNeckSeam;
}

function getStitchWidthPercentage(labelType: LabelType, stitchType: WovenLabelStitchType) {
  if (stitchType == WovenLabelStitchType.TwoSide) return 0;

  switch (labelType) {
    case LabelType["45x45"]:
      return 0.2;
    case LabelType["50x18"]:
      return 0.1;
    case LabelType["60x20"]:
      return 0.08;
    case LabelType["65x15"]:
      return 0.06;
    default:
      return 0;
  }
}

export function calcLabelSizePosition(colorConfig: IColor, artwork: CanvaArtwork, orientation: OrientationType) {
  // const productConfig = productsConfig[productHandle];
  const { labelX, labelY, _imagePxPerCm } = colorConfig.orientationCardsValue[orientation].productSetterData;
  const [labelXCm, labelYCm] = map([labelX, labelY], (n) => Number(n) / (_imagePxPerCm || 1));
  // const [canvaWidthCm] = productConfig.canvaDimensions?.[orientation] || [0, 0]; // cm to mm

  const { labelSize, labelPosition, labelType, labelStitch } = artwork.metadata as CanvaImageItemMetadata;
  const [labelWidthCm, labelHeightCm] = map(labelSize, mm2cm);

  const stitchWidth = multiply(labelWidthCm, getStitchWidthPercentage(labelType, labelStitch));
  const width = labelWidthCm + stitchWidth;
  const height = labelHeightCm;

  const x = labelXCm - width / 2;
  let y = labelYCm;
  y += labelPosition === WovenLabelPositionType.BelowNeckTape ? consts.OFFSET_BELOW_NECK_TAPE : 0;

  return { x, y, width, height };
}

export function calcLabelArtworkSizePosition({
  colorConfig,
  labelArtwork,
  labelBgArtwork,
  orientation,
}: {
  colorConfig: IColor;
  labelArtwork: CanvaArtwork;
  labelBgArtwork: CanvaArtwork;
  orientation: OrientationType;
}) {
  const { labelType, labelStitch } = labelBgArtwork.metadata as CanvaImageItemMetadata;
  const labelArtworkRatio = (labelArtwork.metadata as CanvaImageItemMetadata).ratio;
  const {
    x: labelBgX,
    y: labelBgY,
    width: labelBgWidth,
    height: labelBgHeight,
  } = calcLabelSizePosition(colorConfig, labelBgArtwork, orientation);

  const MARGIN_PERCENTAGE_X = getStitchWidthPercentage(labelType, labelStitch) + 0.25;
  const MARGIN_PERCENTAGE_Y = 0.3;

  let width = labelBgWidth * (1 - MARGIN_PERCENTAGE_X);
  let height = width / labelArtworkRatio;
  if (gt(height, (1 - MARGIN_PERCENTAGE_Y) * labelBgHeight)) {
    height = (1 - MARGIN_PERCENTAGE_Y) * labelBgHeight;
    width = height * labelArtworkRatio;
  }

  const x = labelBgX + (labelBgWidth - width) / 2;
  const y = labelBgY + (labelBgHeight - height) / 2;

  return { x, y, width, height };
}

export function getDigitalPrintArtworksCumulativeHeight(canvaOrientations: CanvaOrientations) {
  const result = flow(
    fp.omit(OrientationType.Label),
    values,
    fp.map(property("artworks")),
    fp.sumBy(getOrientationArtworksCumulativeHeight)
  )(canvaOrientations);
  return result;
}

export function getOrientationArtworksCumulativeHeight(artworks: CanvaArtwork[]) {
  artworks = flow(
    cloneDeep, // to not mess with references
    fp.filter(matchesProperty("metadata.type", ArtworkType.body_artwork)), // Filter body artworks
    fp.filter(matchesProperty("metadata.embellishmentType", EmbellishmentType.DigitalPrint)), // Filter Digital print artworks only
    fp.sortBy(property("location.y")) // Sort artworks by their y locations
  )(artworks);

  if (artworks.length == 0) return 0;

  let total = 0;
  let currentStart = artworks[0].location.y;
  let currentEnd = artworks[0].location.y + artworks[0].dimensions.height;

  for (let i = 1; i < artworks.length; i++) {
    const artwork = artworks[i];

    // Check if there is an overlap with the current artwork
    if (artwork.location.y <= currentEnd) {
      // Extend the current range if necessary
      currentEnd = Math.max(currentEnd, artwork.location.y + artwork.dimensions.height);
    } else {
      // No overlap, add the previous range to the total height
      total += currentEnd - currentStart;

      // Start a new range
      currentStart = artwork.location.y;
      currentEnd = artwork.location.y + artwork.dimensions.height;
    }
  }

  // Add the last range to the total height
  total += currentEnd - currentStart;

  return total;
}

export async function convertWebPToPng(webpBytes: ArrayBuffer): Promise<Result<Uint8Array, ErrorObject>> {
  try {
    const blob = new Blob([webpBytes], { type: "image/webp" });
    const img = document.createElement("img");
    img.src = URL.createObjectURL(blob);

    const imageLoadPromise = new Promise<void>((resolve) => {
      img.onload = () => resolve();
    });

    await imageLoadPromise;

    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    const ctx = canvas.getContext("2d");
    if (!ctx) {
      return err({ message: "Failed to get canvas context" });
    }

    ctx.drawImage(img, 0, 0);

    const pngBlob = await new Promise<Blob | null>((innerResolve) => {
      canvas.toBlob((blob) => innerResolve(blob), "image/png");
    });

    if (!pngBlob) {
      return err({ message: "Failed to convert WebP to PNG using canvas" });
    }

    const pngArrayBuffer = await pngBlob.arrayBuffer();
    return ok(new Uint8Array(pngArrayBuffer));
  } catch (error: any) {
    return err({ message: error.message || "Unexpected error occurred" });
  }
}

export const generatePdfFromImages = async (imageUrls: string[]): Promise<Result<Uint8Array, ErrorObject>> => {
  try {
    const pdfDoc = await PDFDocument.create();

    for (const url of imageUrls) {
      const response = await fetch(url);

      if (!response.ok) {
        return err({
          message: `Failed to fetch image: ${url}`,
          code: response.status.toString(),
        });
      }

      const imageBytes = await response.arrayBuffer();

      let image;
      if (url.endsWith(".png")) {
        image = await pdfDoc.embedPng(imageBytes);
      } else if (url.endsWith(".webp")) {
        const pngResult = await convertWebPToPng(imageBytes); // Convert WebP to PNG
        if (pngResult.isErr()) {
          return err(pngResult.error); // Propagate the error
        }
        image = await pdfDoc.embedPng(pngResult.value); // Use the successful result
      } else if (url.endsWith(".jpg") || url.endsWith(".jpeg")) {
        image = await pdfDoc.embedJpg(imageBytes);
      } else {
        return err({ message: `Unsupported image type: ${url}` });
      }

      const imageDims = image.scale(1);
      const page = pdfDoc.addPage([imageDims.width, imageDims.height]);
      page.drawImage(image, {
        x: 0,
        y: 0,
        width: imageDims.width,
        height: imageDims.height,
      });
    }

    const pdfBytes = await pdfDoc.save();
    return ok(pdfBytes);
  } catch (error: any) {
    return err({ message: error.message || "Unexpected error occurred" });
  }
};

export function extractArtworkImageUrls(canvaOrientations: CanvaOrientations, types: ArtworkType[]): string[] {
  const uniqueUrls = new Set<string>();

  Object.values(canvaOrientations).forEach((orientation) => {
    orientation.artworks?.forEach((artwork) => {
      const metadata = artwork.metadata as CanvaImageItemMetadata;
      if (types.includes(metadata.type as ArtworkType) && "imageUrl" in metadata) {
        uniqueUrls.add(metadata.imageUrl);
      }
    });
  });

  return Array.from(uniqueUrls);
}
