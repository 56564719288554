import artworksPricing, { ArtworksPricing, PricePerMeasure } from "@/config/artworksPricingConfig";
import colorsPricing, { ColorsPricing } from "@/config/colorsPricingConfig";
import consts from "@/config/consts";
import labelsPricing from "@/config/labelsPricingConfig";
// import { productsConfig } from "@/config/productsConfig";
import { Tables } from "@/types";
import { JoinedPriceModifier, PriceChangeResult } from "@/types/price-modifiers/priceModifiers";
import { SizeQuantity } from "@/types/SizeQuantity";
import {
  ArtworkCustomization,
  ArtworkFile,
  ColorCustomization,
  LabelCustomization,
  Size,
} from "@/types/supabase-custom/Customization";
import { JoinedCart } from "@/types/supabase-custom/JoinedCart";
import { JoinedLineItem } from "@/types/supabase-custom/JoinedLineItem";
import { JoinedProduct } from "@/types/supabase-custom/JoinedProduct";
import _, { divide, filter, flow, isEqual, map, maxBy, multiply, property, round, sumBy, values } from "lodash";
import { find, gte, matchesProperty, reverse, sortBy } from "lodash/fp";
import { calculateLeadTime, calculateLineItemPrice, getItemPriceFromImpact } from "./priceModifierUtils";

// export function generateSizeAllocations(sizes: string[], minQuantity = 50) {
//   const sizePerQuantity = floor(divide(minQuantity, sizes.length));

//   const result = map(sizes, function addQuantity(size, index) {
//     const isLastSize = eq(index, subtract(sizes.length, 1));
//     const remainder = minQuantity % sizes.length;
//     return {
//       size,
//       quantity: isLastSize ? sizePerQuantity + remainder : sizePerQuantity,
//     };
//   });

//   return result;
// }
export function generateSizeAllocations(product: Tables<"products">, minQuantity = 50, isBlank = false) {
  // Check if blank quantity should be 1
  minQuantity = isBlank ? 1 : minQuantity;
  // Initial weights that should sum to 1
  const sizeWeights = isBlank
    ? {
        "2XS": 0,
        XS: 0,
        S: 0,
        M: 0,
        L: 1,
        XL: 0,
        "2XL": 0,
        "3XL": 0,
        "4XL": 0,
        "5XL": 0,
      }
    : {
        "2XS": 0,
        XS: 0.1,
        S: 0.2,
        M: 0.24,
        L: 0.24,
        XL: 0.12,
        "2XL": 0.1,
        "3XL": 0,
        "4XL": 0,
        "5XL": 0,
      };
  console.log("👍", minQuantity, sizeWeights);
  // Filter out the available sizes and calculate the total of their weights
  // const sizes = product.sizes as string[];
  const availableSizes = values(Size).filter((size) => sizeWeights[size] !== undefined);
  const totalWeight = sumBy(availableSizes, (size) => sizeWeights[size]);

  // Recalculate the weights so they sum to 1 based on the available sizes
  const adjustedWeights = map(availableSizes, (size) => {
    return {
      size,
      weight: sizeWeights[size] / totalWeight, // Normalize the weight
    };
  });
  console.log("adjustedWeights", adjustedWeights);
  // Calculate the total quantities for each available size based on the adjusted weights
  const result = map(adjustedWeights, ({ size, weight }) => {
    return {
      size,
      quantity: Math.floor(minQuantity * weight), // Floor to get whole numbers
    };
  });
  console.log("result", result);
  // Calculate the total quantity allocated so far
  const totalAllocatedQuantity = result.reduce((sum, { quantity }) => sum + quantity, 0);

  // Calculate the remainder (if any)
  const remainder = minQuantity - totalAllocatedQuantity;

  // If there's a remainder, find the size with the highest weight and give the extra quantity to it
  if (remainder > 0) {
    const highestWeightSize = maxBy(result, ({ size }) => sizeWeights[size]);
    if (highestWeightSize) {
      highestWeightSize.quantity += remainder;
    }
  }

  return result;
}

// export function calculateSingleItemPrice(
//   product: JoinedProduct,
//   configuration: Tables<"configurations">,
//   quantity = consts.MIN_PURCHASE_QUANTITY
// ) {
//   const priceList = product.price_lists ? product.price_lists.price_lists_components : [];

//   // 1. base product cost
//   const productCost =
//     flow(
//       sortBy("threshold"),
//       reverse,
//       find(flow(property("threshold"), gte(quantity))),
//       property("price")
//     )(priceList) || 0;

//   // 2. configurations cost
//   // a. color
//   const colorCost = (function calculateColorCost() {
//     const colorMetadata = configuration.color_metadata as ColorCustomization | null;
//     if (!colorMetadata) {
//       return 0;
//     }

//     const result = flow(
//       find(matchesProperty("category", colorMetadata.category)),
//       property("price_per_quantity"),
//       reverse,
//       find(flow(property("threshold"), gte(quantity))),
//       property("price")
//     )(colorsPricing);

//     return result || 0;
//   })();

//   // b. artwork
//   const artworkCost = (function calculateArtworkCost() {
//     const artworkMetadata = configuration.artwork_metadata as ArtworkCustomization | null;
//     if (!artworkMetadata) {
//       return 0;
//     }

//     const artworkOrientations = filter(
//       [artworkMetadata.front, artworkMetadata.back, artworkMetadata.left, artworkMetadata.right],
//       Boolean
//     ) as ArtworkFile[];

//     const result = sumBy(artworkOrientations, function getOrientationCost(artworkOrientation) {
//       if (!artworkOrientation.url) {
//         return 0;
//       }

//       const result = flow(
//         find(matchesProperty("embellishment_type", artworkOrientation.embellishment_type)),
//         property("price_per_measure"),
//         reverse,
//         find(function matchesArtworkMeasure(pricePerMeasure: PricePerMeasure) {
//           return (
//             gte(artworkOrientation.width, pricePerMeasure.measure.width) &&
//             gte(artworkOrientation.height, pricePerMeasure.measure.height)
//           );
//         }),
//         property("price_per_quantity"),
//         reverse,
//         find(flow(property("threshold"), gte(quantity))),
//         property("price")
//       )(artworksPricing);

//       return result || 0;
//     });

//     return result;
//   })();

//   const labelCost = (function labelCost() {
//     const labelMetadata = configuration.label_metadata as LabelCustomization | null;
//     if (!labelMetadata) {
//       return 0;
//     }

//     const result = flow(find(matchesProperty("type", labelMetadata.type)), property("price"))(labelsPricing);

//     return result || 0;
//   })();

//   return productCost + colorCost + artworkCost + labelCost;
// }

export function calculateItemPrice(lineItem: JoinedLineItem) {
  const totalQuantity = _.sumBy(lineItem.size_allocations, "quantity");

  return (
    totalQuantity * getItemPriceFromImpact(lineItem.price_details as PriceChangeResult, totalQuantity)
    // totalQuantity * calculateLineItemPrice(lineItem.configurations.products, lineItem.configurations,lineItem.price_details, totalQuantity)
    // totalQuantity * calculateSingleItemPrice(lineItem.configurations.products, lineItem.configurations, totalQuantity)
  );
}

export function calculateCartSubtotal(cart: JoinedCart) {
  return _.sumBy(cart.line_items, calculateItemPrice);
}

export function calculateCartTotalPrice(cart: JoinedCart) {
  const subtotal = calculateCartSubtotal(cart);

  // 1. subtotal price
  let result = subtotal;

  // 2. discount
  if (cart.discounts) {
    result -= calculateDiscount(subtotal, cart.discounts);
  }

  // 3. shipping fees
  result += calculateShippingFees(cart);

  // 4. tax fees
  if (cart.total_tax) result += cart.total_tax;
  result = Number(result.toFixed(2));
  return result;
}

export function calculateDiscount(price: number, discount: Tables<"discounts">) {
  if (!discount.value) return 0;

  const discountValueType = discount.value_type || "fixed_amount";
  if (discountValueType == "fixed_amount") {
    return discount.value;
  }
  return round(multiply(price, divide(discount.value, 100)));
}

export function calculateShippingFees(cart: JoinedCart) {
  return _.sumBy(cart.line_items, "shipping_fees");
}

// export function calculateLeadTime(product: JoinedProduct, configuration: Tables<"configurations">) {
//   // 1. base product cost
//   const productLeadTime = product.lead_time || 2;

//   // 2. configurations lead time
//   // a. color
//   const colorLeadTime = (function calculateColorLeadTime() {
//     const colorMetadata = configuration.color_metadata as ColorCustomization | null;
//     if (!colorMetadata) {
//       return 2;
//     }

//     const result = flow(
//       find(matchesProperty("category", colorMetadata.category)),
//       property<ColorsPricing, number>("lead_time")
//     )(colorsPricing);

//     return result || 2;
//   })();

//   // b. artwork
//   const artworkLeadTime = (function calculateArtworkLeadTime() {
//     const artworkMetadata = configuration.artwork_metadata as ArtworkCustomization | null;
//     if (!artworkMetadata) {
//       return 2;
//     }

//     const artworkOrientations = filter(
//       [artworkMetadata.front, artworkMetadata.back, artworkMetadata.left, artworkMetadata.right],
//       Boolean
//     ) as ArtworkFile[];

//     const result = sumBy(artworkOrientations, function getOrientationCost(artworkOrientation) {
//       if (!artworkOrientation.url) {
//         return 2;
//       }

//       const result = flow(
//         find(matchesProperty("embellishment_type", artworkOrientation.embellishment_type)),
//         property<ArtworksPricing, number>("lead_time")
//       )(artworksPricing);

//       return result || 2;
//     });

//     return result;
//   })();

//   const labelLeadTime = (function calculateLabelLeadTime() {
//     const labelMetadata = configuration.label_metadata as LabelCustomization | null;
//     if (!labelMetadata) {
//       return 2;
//     }

//     const result = flow(find(matchesProperty("type", labelMetadata.type)), property("lead_time"))(labelsPricing);

//     return result || 2;
//   })();

//   return productLeadTime + colorLeadTime + artworkLeadTime + labelLeadTime;
// }

// export function calculateProductionTime(lineItems: JoinedLineItem[]): number {
//   const productionTime = Math.max(
//     ...lineItems.map((item) => {
//       const configuration = item.configurations;
//       const product = item.configurations.products;
//       return calculateLeadTime(product, configuration);

//       // return calculateLeadTime(product, configuration);
//     })
//   );
//   return productionTime;
// }

export function findUpdatedLineItems(oldItems: JoinedLineItem[], newItems: JoinedLineItem[]): JoinedLineItem[] {
  return newItems.filter((newItem) => {
    const oldItem = oldItems.find((item) => item.id === newItem.id);
    return oldItem && !isEqual(oldItem.size_allocations, newItem.size_allocations);
  });
}

export function calculateTotalLineItemQuantity(sizeAllocations: SizeQuantity[]) {
  return sizeAllocations.reduce((total, allocation) => total + allocation.quantity, 0);
}

export function calculateTotalPerSize(items: Tables<"line_items">[], size: string): number {
  let totalForSize = 0;

  items.forEach((item) => {
    const sizeAllocations = item.size_allocations as any;

    sizeAllocations.forEach((allocation: SizeQuantity) => {
      if (allocation.size.toLowerCase() === size.toLowerCase()) {
        totalForSize += allocation.quantity;
      }
    });
  });

  return totalForSize;
}

export function calculateTotalOfAllSizes(items: Tables<"line_items">[]): number {
  let grandTotal = 0;

  items.forEach((item) => {
    const sizeAllocations = item.size_allocations as any;

    sizeAllocations.forEach((allocation: SizeQuantity) => {
      grandTotal += allocation.quantity;
    });
  });

  return grandTotal;
}

export function getAllUniqueSizes(lineItems: Tables<"line_items">[]): string[] {
  const sizeSet = new Set<string>();

  lineItems.forEach((item) => {
    const sizeAllocations = item.size_allocations as any;

    sizeAllocations.forEach((allocation: SizeQuantity) => {
      const allocationSize = allocation.size.toUpperCase();
      sizeSet.add(allocationSize);
    });
  });

  return Array.from(sizeSet);
}

export function updateAttributes(cart: JoinedCart, attribute: { name: string; value: string | number }): JoinedCart {
  const attributes = (cart.attributes as any as { name: string; value: string | number }[]) || [];

  const existingAttribute = attributes.find((attr) => attr.name === attribute.name);

  const updatedAttributes = existingAttribute
    ? attributes.map((attr) => (attr.name === attribute.name ? { ...attr, value: attribute.value } : attr))
    : [...attributes, attribute];

  return {
    ...cart,
    attributes: updatedAttributes,
  };
}
