import { LabelType } from "@/config/labelsPricingConfig";
import { EmbellishmentType } from "../enums/embellishmentType.enum";
import { WovenLabelPositionType } from "../enums/wovenLabelPositionType.enum";
import { WovenLabelStitchType } from "../enums/wovenLabelStitchType.enum";
import { WovenLabelSizeType } from "../store/StoreModel";

/**
 * Colors
 */
export interface ColorCustomization {
  uuid?: string;
  name: string;
  hex: string;
  contrast?: number;
  pantone: string | null;
  category: "pre-developed" | "custom";
  default?: true;
}

/**
 * Artworks
 */
export interface ArtworkFile {
  uuid?: string;
  originalWidth?: number;
  originalHeight?: number;
  width?: number;
  height?: number;
  from_center?: number;
  from_neck_seam?: number;
  ratio?: number; // width / height
  url?: string;
  name?: string;
  size?: number;
  embellishment_type?: EmbellishmentType;
  color_selection_technique?: "standard" | "3d-puff";
  color_selection?: ColorCustomization;
  location?: { x: number; y: number };
  dimensions?: { width: number; height: number };
  is_flipped_y?: boolean;
  is_flipped_x?: boolean;
}

// export interface OrientationConfig {
//   embellishment_type: EmbellishmentType;
//   from_center: number;
//   from_neck_seam: number;
//   artwork_file?: ArtworkFile;
//   color_selection_technique?: "standard" | "3d-puff";
//   color_selection?: ColorCustomization;
// }

// Partial<Record<OrientationType, ArtworkCustomizationType>> | null;
export interface ArtworkCustomization {
  // TODO: front and back orientations will support multiple artwork files
  minimum_required_size: {
    front: string;
    back: string;
    // left: string;
    // right: string;
    label: string;
  };
  // front?: ArtworkFile;
  // back?: ArtworkFile;
  // left?: ArtworkFile;
  // right?: ArtworkFile;
  front?: ArtworkFile[];
  back?: ArtworkFile[];
  // left?: ArtworkFile[];
  // right?: ArtworkFile[];
  label?: ArtworkFile[];
}

export enum Size {
  "2XS" = "2XS",
  "XS" = "XS",
  "S" = "S",
  "M" = "M",
  "L" = "L",
  "XL" = "XL",
  "2XL" = "2XL",
  "3XL" = "3XL",
  "4XL" = "4XL",
  "5XL" = "5XL",
}

/**
 * Labels
 */

export interface LabelCustomization {
  type: LabelType;
  artwork_file?: ArtworkFile;
  position: WovenLabelPositionType;
  size: [number, number];
  stitch: WovenLabelStitchType;
  url?: string;
  location?: { x: number; y: number };
  dimensions?: { width: number; height: number };
  name?: string;
  uuid?: string;
}
