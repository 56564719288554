import { Tables } from "@/types";

export enum PriceModifierScope {
  line_item = "line_item",
  cart = "cart",
}

export enum PriceModifierType {
  price = "price",
  addon = "addon",
}

export enum PriceModifierStatus {
  draft = "draft",
  enabled = "enabled",
  disabled = "disabled",
}

export type JoinedPriceModifier = Partial<Tables<"price_modifiers">> & {
  price_modifiers_components?: Tables<"price_modifiers_components">[];
  product_id?: number | null;
};

export type JoinedPriceModifierComponents = Tables<"price_modifiers_components"> & { products: Tables<"products"> };

export type JoinedPriceModifierAssignment = Tables<"price_modifiers_assignments"> & {
  price_modifiers: JoinedPriceModifier;
};

export type Trigger = {
  property: DecidingProperty;
  operator: "eq" | "neq" | "lt" | "lte" | "gt" | "gte" | "in" | "nin";
  value: any;
};

export interface TriggerPayload {
  alphabetReference: string;
  triggerIndex?: number;
  key?: "property" | "operator" | "value";
  value?: string | number | boolean;
}

export const triggerOperators = ["eq", "neq", "lt", "lte", "gt", "gte", "in", "nin"];

export const booleanTriggerOperators = [
  { label: "Equal", value: "eq" },
  { label: "Not Equal", value: "neq" },
];

export const numberTriggerOperators = [
  { label: "Equal", value: "eq" },
  { label: "Not Equal", value: "neq" },
  { label: "Less Than", value: "lt" },
  { label: "Less Than or Equal", value: "lte" },
  { label: "Greater Than", value: "gt" },
  { label: "Greater Than or Equal", value: "gte" },
];

export const triggerOperatorsLabelValues = [
  { label: "Equal", value: "eq" },
  { label: "Not Equal", value: "neq" },
  { label: "Less Than", value: "lt" },
  { label: "Less Than or Equal", value: "lte" },
  { label: "Greater Than", value: "gt" },
  { label: "Greater Than or Equal", value: "gte" },
  { label: "In", value: "in" },
  { label: "Not In", value: "nin" },
];

export const rushOrdersOperators = [{ label: "Equal", value: "eq" }];

export type PriceImpact = { qty: number; price: number };

export type Impact = {
  price?: PriceImpact[];
  lead_time?: number;
  moq?: number;
  impactPerCM2?: boolean;
  embellishmentType?: string;
  extraCost?: boolean;
};

export type PriceModifierComponent = {
  id: number;
  price_modifier_id: number | null;
  triggers: Trigger[] | null;
  impact: Impact;
};

export type PriceChangeResult = {
  moqChange: number;
  leadTimeChange: number;
  priceChange: PriceImpact[];
};

export enum DecidingProperty {
  // colour
  color_type = "color_type",

  // label
  label_size = "label_size",

  // dtg
  dtg_cumulative_height = "dtg_cumulative_height",
  dtg_surface = "dtg_surface",

  // dtf
  dtf_surface = "dtf_surface",

  // screen printing
  screen_print_artwork_size = "screen_print_artwork_size",
  screen_print_puff_artwork_size = "screen_print_puff_artwork_size",

  // embroidery
  has_embroidery = "has_embroidery",
  has_embroidery_with_puff = "has_embroidery_with_puff",
  embroidery_surface = "embroidery_surface",

  // rush order
  deliver_in = "deliver_in",
  save_days = "save_days",
}

export enum ColorSelectionTechnique {
  Standard = "standard",
  Puff = "3d-puff",
}
